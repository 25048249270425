import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import ProductAttributeForm from '../components/ProductAttributeForm';
import { useUpdateProductAttributeMutation } from '../graphql';
import type { CoreProductAttribute } from '../types';

interface Props {
  item: CoreProductAttribute;
  onClose: () => void;
}

const EditProductAttribute: FC<Props> = ({ item, onClose }) => {
  const { addSuccess, addError } = useNotification();
  const [updateAttribute] = useUpdateProductAttributeMutation();

  const handleSubmit = async (values: Pick<CoreProductAttribute, 'name' | 'type'>): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateAttribute({
          refetchQueries: ['productAttributes'],
          variables: {
            input: {
              id: item.id,
              name: values.name
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Product attribute updated successfully.');
    onClose();
  };

  return (
    <ProductAttributeForm
      buttonText="Save"
      initialValues={item}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default EditProductAttribute;
