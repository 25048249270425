import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { SelectOption } from '@zen/DesignSystem/types';
import type { EntitledAccountsPayload } from '@zen/graphql/types.generated';

import { getEntitledManageableAccountOptions } from '../AccountDetails/AccountLocations/AddLocationModal/helpers';
import { useGetEntitledAccountsLazyQuery } from '../AccountDetails/graphql/getEntitledAccounts.generated';

type EntitledAccountOptionsArguments = {
  accountId: string;
  selectedAccountId?: string;
};

export const useEntitledAccountOptions = (args: EntitledAccountOptionsArguments) => {
  const { accountId, selectedAccountId } = args;
  const [fetchEntitledAccounts, { data, loading, error }] = useGetEntitledAccountsLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    fetchEntitledAccounts({
      variables: {
        accountId,
        searchQuery: '',
        selectedAccountId
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchEntitledAccounts({
        variables: {
          accountId,
          searchQuery: query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const options: SelectOption<string>[] =
    getEntitledManageableAccountOptions(data?.entitledAccounts as EntitledAccountsPayload) || [];

  return {
    options,
    loading,
    error,
    onSearch: debouncedHandleInputChange
  };
};
