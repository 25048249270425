import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateProductCategoryMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateCoreProductCategoryInput;
}>;

export type CreateProductCategoryMutationResult = { __typename?: 'Mutation' } & {
  createCoreProductCategory: { __typename?: 'CreateCoreProductCategoryPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
  };
};

export const CreateProductCategoryDocument = /* #__PURE__ */ gql`
  mutation createProductCategory($input: CreateCoreProductCategoryInput!) {
    createCoreProductCategory(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutationResult, CreateProductCategoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateProductCategoryMutationResult, CreateProductCategoryMutationVariables>(
    CreateProductCategoryDocument,
    options
  );
}
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
