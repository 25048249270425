import type { RadioGroupOption } from '@zen/Components/RadioGroup/types';

import type { BusinessUnitsAssigments } from './types';

export const businessUnitsAssignmentOptions: RadioGroupOption<BusinessUnitsAssigments>[] = [
  {
    label: 'All business units',
    value: 'all-business-units'
  },
  {
    label: 'Specific business units',
    value: 'specific-business-units'
  }
];
