import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCurrentUserQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetCurrentUserQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<
      GraphQLTypes.Account,
      'organizationalRole' | 'parentAccountId' | 'referencePrefix' | 'status' | 'tradingName' | 'type'
    > & { businessUnits: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName' | 'type'>> }
  >;
  authorization: { __typename?: 'Authorization' } & Pick<GraphQLTypes.Authorization, 'isAdmin'>;
  currentUser?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUser' } & Pick<GraphQLTypes.CurrentUser, 'uuid'> & {
        profile?: GraphQLTypes.Maybe<
          { __typename?: 'current_user_profile' } & Pick<
            GraphQLTypes.CurrentUserProfile,
            'email' | 'firstName' | 'lastName' | 'phoneNumber'
          >
        >;
      }
  >;
  currentUserDetails?: GraphQLTypes.Maybe<
    { __typename?: 'AccountMember' } & Pick<
      GraphQLTypes.AccountMember,
      'email' | 'firstName' | 'id' | 'isProfileCompleted' | 'lastName' | 'phoneNumber'
    > & {
        account: { __typename?: 'Account' } & Pick<
          GraphQLTypes.Account,
          'id' | 'organizationalRole' | 'parentAccountId' | 'status'
        > & { ssoDetails: { __typename?: 'SsoDetails' } & Pick<GraphQLTypes.SsoDetails, 'enabled'> };
        featureFlags: Array<
          { __typename?: 'current_user_feature_flag' } & Pick<GraphQLTypes.CurrentUserFeatureFlag, 'enabled' | 'name'>
        >;
      }
  >;
};

export const GetCurrentUserDocument = /* #__PURE__ */ gql`
  query getCurrentUser($accountId: String!) {
    account(accountId: $accountId) {
      businessUnits {
        id
        tradingName
        type
      }
      organizationalRole
      parentAccountId
      referencePrefix
      status
      tradingName
      type
    }
    authorization {
      isAdmin
    }
    currentUser {
      profile {
        email
        firstName
        lastName
        phoneNumber
      }
      uuid
    }
    currentUserDetails {
      account {
        id
        organizationalRole
        parentAccountId
        ssoDetails {
          enabled
        }
        status
      }
      email
      featureFlags(accountId: $accountId) {
        enabled
        name
      }
      firstName
      id
      isProfileCompleted
      lastName
      phoneNumber
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCurrentUserQueryResult, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
