import { type FC, useState } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Modal } from '@zen/DesignSystem';

import AccountDetailsItem from '../../components/AccountDetailsItem';
import { locationUsageLabelMapping } from '../helpers';
import type { UsageContext } from '../types';
import UpdateLocationDetails from '../UpdateLocationDetails';

interface Props {
  accountId: string;
  canEdit: boolean;
  locationAccountId: string;
  locationId: string;
  name: string;
  usage: UsageContext;
}

const LocationDetails: FC<Props> = ({ accountId, canEdit, locationAccountId, locationId, name, usage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={toggleModal} title="Update details">
        <UpdateLocationDetails
          accountId={accountId}
          initialValues={{
            locationAccountId,
            name,
            usage
          }}
          locationAccountId={locationAccountId}
          locationId={locationId}
          onCancel={toggleModal}
          onSuccess={toggleModal}
        />
      </Modal>
      <EditableContentContainer heading="Details" onEdit={canEdit ? toggleModal : undefined}>
        <AccountDetailsItem fullRow={true} name="Name" value={name} />
        <AccountDetailsItem fullRow={true} name="Usage" value={locationUsageLabelMapping[usage]} />
      </EditableContentContainer>
    </>
  );
};

export default LocationDetails;
