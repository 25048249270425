import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { SegmentedControlOption } from '@zen/DesignSystem/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { DateWithTimeFields } from '../../DateWithTimeForm/types';
import { TimeOption } from '../../DateWithTimeForm/types';
import TimeZoneIcon from '../../TimeZoneIcon';
import FormHiddenInput from '../FormHiddenInput';
import FormSegmentedControl from '../FormSegmentedControl';
import FormTimeInput from '../FormTimeInput';
import { useForm } from '../useForm';
import { getOptions } from './helpers';
import TimeRange from './TimeRange';
import type { TimeRangeEnum } from './TimeRange/types';

const defaultConfig: TimeOption[] = [TimeOption.NO_TIME, TimeOption.POINT_IN_TIME, TimeOption.TIME_RANGE];

interface Props {
  onTimeRangeChange: (timeRange: TimeRangeEnum) => void;
  timeConfig?: TimeOption[];
  timeRange?: Nullable<TimeRangeEnum>;
  timeZone?: Optional<string>;
}

const FormTimeFields: FC<Props> = ({ onTimeRangeChange, timeConfig = defaultConfig, timeRange, timeZone }) => {
  const { values } = useForm<DateWithTimeFields>();
  const options: SegmentedControlOption<TimeOption>[] = getOptions(timeConfig);

  return (
    <>
      {options.length > 1 && <FormSegmentedControl hideLabel={true} name="timeOption" options={options} />}
      {values.timeOption === TimeOption.POINT_IN_TIME && (
        <>
          <div className="flex items-end space-x-2">
            <FormTimeInput hideErrorMessage={true} hideLabel={true} name="time" noMargin={true} />
            <TimeZoneIcon date={values.date} timeZone={timeZone} />
          </div>
          <FormHiddenInput name="time" />
        </>
      )}
      {values.timeOption === TimeOption.TIME_RANGE && (
        <div className="mb-4">
          <div className="flex items-end space-x-2">
            <FormTimeInput hideErrorMessage={true} hideLabel={true} name="startTime" noMargin={true} />
            <Icon className="self-center" icon="zicon-arrow-right" />
            <FormTimeInput hideErrorMessage={true} hideLabel={true} name="endTime" noMargin={true} />
            <TimeZoneIcon date={values.date} timeZone={timeZone} />
          </div>
          <TimeRange className="mt-2 -mb-2" onChange={onTimeRangeChange} value={timeRange} />
          <FormHiddenInput name="startTime" />
          <FormHiddenInput name="endTime" />
        </div>
      )}
    </>
  );
};

export default FormTimeFields;
