import type { Option } from '@zen/DesignSystem/types';
import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';

import type { GetAccountBusinessUnitsQueryResult, GetAccountBusinessUnitsQueryVariables } from '../AccountDetails/graphql';
import { GetAccountBusinessUnitsDocument } from '../AccountDetails/graphql';

type BusinessUnit = {
  id: string;
  tradingName: string;
};

export const useBusinessUnitOptions = (accountId: string): Option<string>[] => {
  const { data: businessUnits } = useAllPaginatedResults<
    GetAccountBusinessUnitsQueryResult,
    GetAccountBusinessUnitsQueryVariables,
    BusinessUnit
  >({
    document: GetAccountBusinessUnitsDocument,
    responsePath: 'accountBusinessUnits',
    variables: { accountId }
  });

  return businessUnits.map((businessUnit) => ({
    label: businessUnit.tradingName,
    value: businessUnit.id
  }));
};
