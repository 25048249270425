import { useContext, useMemo } from 'react';

import type { BusinessUnit, UserProfile } from '@zen/Auth/UserDataProvider/types';
import UserContext from '@zen/Auth/UserDataProvider/UserContext';
import type { AccountStatus, OrganizationalRoleEnum } from '@zen/graphql/types.generated';

import type { Nullable, Optional } from '../typescript';

interface Account {
  accountStatus: Optional<AccountStatus>;
  accountUuid: string;
  divisions: BusinessUnit[];
  organizationalRole: Nullable<OrganizationalRoleEnum>;
  parentAccountId: string;
  referencePrefix: Optional<string>;
  refetchUser: () => void;
  userProfile: UserProfile;
}

const useAccount = (): Account => {
  const {
    accountUuid,
    accountStatus,
    divisions,
    organizationalRole,
    parentAccountId,
    referencePrefix,
    refetchUser,
    userProfile
  } = useContext(UserContext);

  return useMemo(
    () => ({
      accountUuid,
      accountStatus,
      divisions,
      organizationalRole,
      parentAccountId,
      referencePrefix,
      userProfile,
      refetchUser
    }),
    [accountUuid, accountStatus, divisions, organizationalRole, parentAccountId, referencePrefix, userProfile, refetchUser]
  );
};

export default useAccount;
