import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { EntitledAccountsPayload } from '@zen/graphql/types.generated';

import { useGetEntitledAccountsFiltersLazyQuery } from '../AccountDetails/AccountLocations/AccountLocationsFilters/graphql/getEntitledAccountsFilters.generated';

type SearchEntitledAccountWithManagedArguments = {
  accountId: string;
};

export const useSearchEntitledWithManagedAccount = (args: SearchEntitledAccountWithManagedArguments) => {
  const { accountId } = args;
  const [fetchEntitledWithManagedAccounts, { data, loading, error }] = useGetEntitledAccountsFiltersLazyQuery({
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    fetchEntitledWithManagedAccounts({
      variables: {
        accountId,
        searchQuery: ''
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchEntitledWithManagedAccounts({
        variables: {
          accountId,
          searchQuery: query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const entitledAccounts: EntitledAccountsPayload = data?.entitledAccounts as EntitledAccountsPayload;

  return {
    entitledAccounts,
    loading,
    error,
    onSearch: debouncedHandleInputChange
  };
};
