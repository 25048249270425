import type { FC } from 'react';
import { DayPicker } from 'react-day-picker';

import { Chevron, DayButton, YearsDropdown } from './components';
import { DatePickerContext } from './DatePicker.context';
import { formatWeekdayName, prepareCustomProps, prepareMatchers } from './helpers';
import { getClassNames } from './styles';
import type { DatePickerProps } from './types';

const DatePicker: FC<DatePickerProps> = (props) => {
  const { className, disabled, hasError = false, isInline = false, numberOfMonths = 1, tooltip } = props;

  return (
    <DatePickerContext.Provider value={{ tooltip }}>
      <DayPicker
        captionLayout="dropdown-years"
        classNames={getClassNames({ className, hasError, isInline })}
        components={{ DayButton, Chevron, YearsDropdown }}
        data-testid="date-picker"
        disabled={prepareMatchers(disabled)}
        formatters={{ formatWeekdayName }}
        ISOWeek={true}
        numberOfMonths={numberOfMonths}
        {...prepareCustomProps(props)}
      />
    </DatePickerContext.Provider>
  );
};

export default DatePicker;
