import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type AllAccountsQueryVariables = GraphQLTypes.Exact<{
  accountsFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.AccountsFiltersInput>;
  nestedBusinessUnits?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type AllAccountsQueryResult = { __typename?: 'Query' } & {
  accounts?: GraphQLTypes.Maybe<
    { __typename?: 'AccountConnection' } & {
      nodes: Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'> & {
              label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>>;
            }
        >
      >;
    }
  >;
};

export const AllAccountsDocument = /* #__PURE__ */ gql`
  query allAccounts($accountsFiltersInput: AccountsFiltersInput, $nestedBusinessUnits: Boolean) {
    accounts(accountsFiltersInput: $accountsFiltersInput, nestedBusinessUnits: $nestedBusinessUnits) {
      nodes {
        id
        label {
          long
        }
        tradingName
      }
    }
  }
`;

/**
 * __useAllAccountsQuery__
 *
 * To run a query within a React component, call `useAllAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAccountsQuery({
 *   variables: {
 *      accountsFiltersInput: // value for 'accountsFiltersInput'
 *      nestedBusinessUnits: // value for 'nestedBusinessUnits'
 *   },
 * });
 */
export function useAllAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AllAccountsQueryResult, AllAccountsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AllAccountsQueryResult, AllAccountsQueryVariables>(AllAccountsDocument, options);
}
export function useAllAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllAccountsQueryResult, AllAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AllAccountsQueryResult, AllAccountsQueryVariables>(AllAccountsDocument, options);
}
export type AllAccountsQueryHookResult = ReturnType<typeof useAllAccountsQuery>;
export type AllAccountsLazyQueryHookResult = ReturnType<typeof useAllAccountsLazyQuery>;
