const ANALYTICS_RELATIVE_INDEX_PATH = 'analytics';

export const ANALYTICS_ROUTE_PREFIX = `/dashboard/${ANALYTICS_RELATIVE_INDEX_PATH}`;

interface AnalyticsRoutes {
  conversationalAnalytics: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  dashboardDetails: {
    getUrl: (accountId: string, dashboardId: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const analyticsRoutes: AnalyticsRoutes = {
  dashboardDetails: {
    path: `${ANALYTICS_ROUTE_PREFIX}/:accountId/:dashboardId`,
    relativePath: ':accountId/:dashboardId',
    getUrl: (accountId: string, dashboardId: string) => `${ANALYTICS_ROUTE_PREFIX}/${accountId}/${dashboardId}`
  },
  conversationalAnalytics: {
    path: `${ANALYTICS_ROUTE_PREFIX}/conversational-analytics`,
    relativePath: 'conversational-analytics',
    getUrl: () => `${ANALYTICS_ROUTE_PREFIX}/conversational-analytics`
  },
  index: {
    path: `${ANALYTICS_ROUTE_PREFIX}/*`,
    getUrl: () => ANALYTICS_ROUTE_PREFIX,
    relativePath: `${ANALYTICS_RELATIVE_INDEX_PATH}/*`
  }
};

export default analyticsRoutes;
