import { type FC, type ReactNode } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components';
import type { FormInstance } from '@zen/Components/types';
import { Button, Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateAccountNameMutation } from './graphql';
import type { UpdateAccountNameFormType } from './types';
import { updateAccountNameFormValidation } from './updateAccountNameForm.validation';

interface Props {
  accountId: string;
  handleClose: () => void;
  sourceAccountId: string;
  tradingName: string;
}

const UpdateAccountNameModal: FC<Props> = ({ accountId, handleClose, sourceAccountId, tradingName }) => {
  const { addError, addSuccess } = useNotification();
  const [updateAccountName] = useUpdateAccountNameMutation();

  const handleUpdateAccountName = (values: UpdateAccountNameFormType): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateAccountName({
          variables: {
            input: {
              accountId,
              sourceAccountId,
              newTradingName: values.tradingName
            }
          }
        }),
      onError: () => addError('There was an error updating the account name. Please try again.')
    });
  };

  const initialValues: UpdateAccountNameFormType = {
    tradingName: tradingName || ''
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<UpdateAccountNameFormType>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save changes">
      <Button data-testid="cancel-button" onClick={handleClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSuccess = () => {
    handleClose();
    addSuccess('Account name was successfully updated.');
  };

  return (
    <Modal isOpen={!!accountId} onClose={handleClose} title="Update company name">
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleUpdateAccountName}
        onSuccess={handleSuccess}
        validationSchema={updateAccountNameFormValidation}
      >
        {() => <FormInput label="Company name" name="tradingName" />}
      </Form>
    </Modal>
  );
};

export default UpdateAccountNameModal;
