import { AccountStatus, ConnectionSourceTargetEnum } from '../../types';
import type { AccountFilterItem } from './types';

const buildFilters = (): AccountFilterItem[] => {
  return [
    {
      title: 'Status',
      key: 'status',
      props: {
        options: [
          { value: AccountStatus.ACTIVE, label: 'Active' },
          { value: AccountStatus.INACTIVE, label: 'Inactive' },
          { value: AccountStatus.MANAGED, label: 'Managed' },
          { value: AccountStatus.PENDING, label: 'Pending' }
        ]
      },
      componentType: 'multi-select'
    },
    {
      title: 'Type',
      key: 'connectionSourceTarget',
      props: {
        options: [
          { value: ConnectionSourceTargetEnum.SOURCE, label: 'Customer' },
          { value: ConnectionSourceTargetEnum.TARGET, label: 'Supplier' },
          { value: ConnectionSourceTargetEnum.BOTH, label: 'Both' }
        ]
      },
      componentType: 'select'
    }
  ];
};

export { buildFilters };
