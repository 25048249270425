import type { FC } from 'react';

import { FormMultiSelect, FormSelect } from '@zen/Components';
import type { Option } from '@zen/DesignSystem/types';
import type { Optional } from '@zen/utils/typescript';

import { useGetAccountBusinessUnitsQuery } from '../../graphql';
import type { BusinessUnit } from '../../types';

interface Props {
  accountId: string;
  className?: string;
  helperText?: string;
  hideLabel?: boolean;
  isMultiple?: boolean;
  isOptional?: boolean;
  label?: string;
  name: string;
  noMargin?: boolean;
}

const FormBusinessUnitsSelect: FC<Props> = ({
  accountId,
  className,
  hideLabel = true,
  isMultiple = true,
  noMargin = true,
  ...props
}) => {
  const { data, loading, error } = useGetAccountBusinessUnitsQuery({
    variables: { accountId },
    fetchPolicy: 'cache-and-network'
  });

  const prepareBusinessUnitOptions = (businessUnits: Optional<BusinessUnit>[]): Option<string>[] => {
    return businessUnits?.map(
      (businessUnit: Optional<BusinessUnit>): Option<string> => ({
        label: businessUnit?.tradingName || '',
        value: businessUnit?.id || ''
      })
    );
  };

  const Select = isMultiple ? FormMultiSelect : FormSelect;

  return (
    <Select
      className={className}
      hasError={!!error}
      hideLabel={hideLabel}
      isLoading={loading}
      noMargin={noMargin}
      options={prepareBusinessUnitOptions(data?.accountBusinessUnits?.nodes || [])}
      renderMenuInPortal={true}
      {...props}
    />
  );
};

export default FormBusinessUnitsSelect;
