import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';

import { type Account, AccountStatus } from '../types';
import ActivateAccount from './ActivateAccount';
import DeactivateAccount from './DeactivateAccount';
import DemoAccountDetails from './DemoAccountDetails';
import InternalSupplyDetails from './InternalSupplyDetails';
import LegalDetails from './LegalDetails';
import MainDetails from './MainDetails';

interface Props {
  accountDetails: Account;
}
const GeneralAccountDetails: FC<Props> = ({ accountDetails }) => {
  const {
    businessUnits,
    demoMode,
    hasBusinessUnitsThatSupplyEachOther,
    tradingName,
    referencePrefix,
    legalInformation,
    id: accountId,
    status,
    permissions
  } = accountDetails;

  const { check } = useGlobalPermissions();

  const canActivateAccount: boolean = check('accounts.canActivate');

  const isAccountInactive: boolean = status === AccountStatus.INACTIVE;
  const hasBusinessUnits: boolean = businessUnits.length > 0;

  const canDeactivateAccount: boolean = !!permissions?.settings?.canDeactivate?.value;
  const canEditMainDetails: boolean = !!permissions?.settings?.canEditAccountDetails?.value;
  const canEditLegalDetails: boolean = !!permissions?.settings?.canEditLegalDetails?.value;
  const canToggleDemoModeAccount: boolean = !!permissions?.settings?.canEditDemoMode?.value;
  const canToggleDemoModeGlobal: boolean = check('accounts.canUpdateDemoMode');
  const canToggleIntraCompanyTrade: boolean = !!permissions?.settings?.canInviteConnection?.value;
  const canViewConnections: boolean = !!permissions?.settings?.canViewConnections;

  const displayToggleDemoMode = (canToggleDemoModeGlobal && isAccountInactive) || canToggleDemoModeAccount;
  const displayIntraCompanyTrade = hasBusinessUnits && canViewConnections;

  return (
    <div className="grid grid-cols-8 gap-8 xl:gap-6 xl:grid-cols-12">
      <MainDetails
        accountId={accountId}
        canEdit={canEditMainDetails}
        referencePrefix={referencePrefix}
        tradingName={tradingName}
      />
      <LegalDetails {...legalInformation} accountId={accountId} canEdit={canEditLegalDetails} />
      {displayToggleDemoMode && <DemoAccountDetails accountId={accountId} canEdit={!isAccountInactive} demoMode={demoMode} />}
      {displayIntraCompanyTrade && (
        <InternalSupplyDetails
          accountId={accountId}
          canEdit={canToggleIntraCompanyTrade}
          hasBusinessUnitsThatSupplyEachOther={!!hasBusinessUnitsThatSupplyEachOther}
        />
      )}
      {canActivateAccount && isAccountInactive && <ActivateAccount accountId={accountId} />}
      {canDeactivateAccount && !isAccountInactive && <DeactivateAccount accountId={accountId} tradingName={tradingName} />}
    </div>
  );
};

export default GeneralAccountDetails;
