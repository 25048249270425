import type { RouteTabConfig } from '@zen/Components/RouteTabs';
import ProductAttributes from '@zen/Products/ProductAttributes';
import ProductCategories from '@zen/Products/ProductCategories';
import { accountSettingsRoutes } from '@zen/Routes';

import AccountConnections from '../AccountDetails/AccountConnections';
import AccountLocations from '../AccountDetails/AccountLocations';
import AccountMembers from '../AccountDetails/AccountMembers';
import BusinessUnits from '../AccountDetails/BusinessUnits';
import GeneralAccountDetails from '../AccountDetails/GeneralAccountDetails';
import { type Account, AccountStatus } from '../AccountDetails/types';
import Webhooks from '../AccountDetails/Webhooks';
import Authentication from '../Authentication';

interface AccountSettingsPermissions {
  canViewAccountGeneral: boolean;
  canViewAuthentication: boolean;
  canViewBusinessUnits: boolean;
  canViewConnections: boolean;
  canViewLocations: boolean;
  canViewMembers: boolean;
  canViewProductSettings: boolean;
  canViewWebhooks: boolean;
}

const getTabsConfig = (
  accountDetails: Account,
  {
    canViewAccountGeneral,
    canViewAuthentication,
    canViewBusinessUnits,
    canViewConnections,
    canViewLocations,
    canViewMembers,
    canViewProductSettings,
    canViewWebhooks
  }: AccountSettingsPermissions
): RouteTabConfig[] => {
  const { id: accountId, permissions, ssoDetails, tradingName } = accountDetails;

  const businessUnits: RouteTabConfig = {
    path: accountSettingsRoutes.businessUnits.relativePath,
    label: 'Business units',
    component: (
      <BusinessUnits
        accountId={accountId}
        accountReferencePrefix={accountDetails.referencePrefix}
        permissions={permissions?.settings}
      />
    ),
    url: accountSettingsRoutes.businessUnits.getUrl()
  };

  const general: RouteTabConfig = {
    path: accountSettingsRoutes.generalInformation.relativePath,
    label: 'General',
    component: <GeneralAccountDetails accountDetails={accountDetails} />,
    url: accountSettingsRoutes.generalInformation.getUrl()
  };

  const members: RouteTabConfig = {
    path: accountSettingsRoutes.members.relativePath,
    label: 'Members',
    component: <AccountMembers accountId={accountId} permissions={permissions?.settings} />,
    url: accountSettingsRoutes.members.getUrl()
  };

  const locations: RouteTabConfig = {
    path: accountSettingsRoutes.locations.relativePath,
    label: 'Locations',
    component: (
      <AccountLocations
        accountId={accountId}
        accountIsArchived={accountDetails.status === AccountStatus.INACTIVE}
        permissions={permissions?.settings}
      />
    ),
    url: accountSettingsRoutes.locations.getUrl()
  };

  const connections: RouteTabConfig = {
    path: accountSettingsRoutes.connections.relativePath,
    label: 'Connections',
    component: <AccountConnections accountId={accountId} permissions={permissions?.settings} />,
    url: accountSettingsRoutes.connections.getUrl()
  };

  const authentication: RouteTabConfig = {
    path: accountSettingsRoutes.authentication.relativePath,
    label: 'Authentication',
    component: (
      <Authentication
        accountId={accountId}
        accountName={tradingName}
        permissions={permissions?.settings}
        ssoDetails={ssoDetails}
      />
    ),
    url: accountSettingsRoutes.authentication.getUrl()
  };

  const productAttributes: RouteTabConfig = {
    path: accountSettingsRoutes.productAttributes.relativePath,
    label: 'Product attributes',
    component: <ProductAttributes accountId={accountId} />,
    url: accountSettingsRoutes.productAttributes.getUrl()
  };

  const productCategories: RouteTabConfig = {
    path: accountSettingsRoutes.productCategories.relativePath,
    label: 'Product categories',
    component: <ProductCategories accountId={accountId} />,
    url: accountSettingsRoutes.productCategories.getUrl()
  };

  const webhooks: RouteTabConfig = {
    path: accountSettingsRoutes.webhooks.relativePath,
    label: 'Webhooks',
    component: <Webhooks accountId={accountId} permissions={permissions?.settings} />,
    url: accountSettingsRoutes.webhooks.getUrl()
  };

  const tabs = [];

  if (canViewAccountGeneral) {
    tabs.push(general);
  }

  if (canViewMembers) {
    tabs.push(members);
  }

  if (canViewLocations) {
    tabs.push(locations);
  }

  if (canViewBusinessUnits) {
    tabs.push(businessUnits);
  }

  if (canViewConnections) {
    tabs.push(connections);
  }

  if (canViewWebhooks) {
    tabs.push(webhooks);
  }

  if (canViewAuthentication) {
    tabs.push(authentication);
  }

  if (canViewProductSettings) {
    tabs.push(productCategories, productAttributes);
  }

  return tabs;
};

export { getTabsConfig };
