import type { FC, ReactNode } from 'react';

import { createShippingLocationValidation } from '@zen/Accounts/AccountForms/CreateAccountForm/createAccountForm.validation';
import { getInitialFormValues } from '@zen/Accounts/AccountForms/helpers';
import BusinessHoursFieldsTable from '@zen/Accounts/components/forms/BusinessHoursFieldsTable';
import BusinessHoursRadioGroup from '@zen/Accounts/components/forms/BusinessHoursRadioGroup';
import ShippingLocationFields from '@zen/Accounts/components/forms/ShippingLocationFields';
import { useEntitledAccountOptions } from '@zen/Accounts/hooks/useEntitledAccountOptions';
import { Form, FormButtons, FormSelect } from '@zen/Components';
import type { FormInstance } from '@zen/Components/types';
import { Button, Modal } from '@zen/DesignSystem';
import {
  type CreateAccountLocationInput,
  type NetworksOrgLoc,
  OrganizationalRoleEnum,
  UsageContext
} from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useCreateAccountLocationMutation } from '../../graphql';
import { prepareValues } from './helpers';
import type { CreateAccountLocationFormInitialValues, CreateAccountLocationFormValues } from './types';

interface Props {
  accountId: string;
  isOpen: boolean;
  onAdd?: (location: Nullable<NetworksOrgLoc>) => void;
  onClose: () => void;
  organizationalRole?: OrganizationalRoleEnum;
}

const AddLocationModal: FC<Props> = ({ accountId, isOpen, onAdd, onClose, organizationalRole }) => {
  const shouldShowLocationUsage = organizationalRole ? organizationalRole === OrganizationalRoleEnum.CONSIGNMENT_PARTY : true;
  const usageContext = shouldShowLocationUsage ? null : UsageContext.BOTH;
  const initialFormValues = getInitialFormValues(usageContext);

  const [createLocation] = useCreateAccountLocationMutation({ refetchQueries: ['getAccountLocations'] });
  const { addError, addSuccess } = useNotification();

  const { options, loading, onSearch } = useEntitledAccountOptions({ accountId });

  const renderFormButtons = ({ isSubmitting }: FormInstance<CreateAccountLocationFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Create location">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSubmit = async (values: CreateAccountLocationFormValues): Promise<IOkOrErrorResult> => {
    const preparedValues: CreateAccountLocationInput = prepareValues(accountId, values);

    return performMutation({
      mutationFn: () =>
        createLocation({
          variables: {
            input: preparedValues
          }
        }),
      onError: () => addError('There was a problem creating the location.'),
      onSuccess: () => addSuccess('Location was successfully created.')
    });
  };

  const initialValues: CreateAccountLocationFormInitialValues = {
    accountId,
    ...initialFormValues.shippingLocation,
    shippingLocationAddress: initialFormValues.shippingLocationAddress,
    businessHours: initialFormValues.businessHours
  };

  const handleSuccess = ({ location }: { location: NetworksOrgLoc }): void => {
    onAdd?.(location);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Create location">
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        validationSchema={createShippingLocationValidation}
      >
        {({ values }: FormInstance<CreateAccountLocationFormValues>) => {
          const { businessHoursOption, shippingLocationAddress } = values;
          const hasCustomBusinessHours: boolean = businessHoursOption === 'custom';

          return (
            <>
              <FormSelect
                className="mb-4"
                isLoading={loading}
                isSearchable={true}
                label="This location is owned by:"
                name="accountId"
                onInputChange={onSearch}
                options={options}
                renderMenuInPortal={true}
              />
              <ShippingLocationFields address={shippingLocationAddress} showLocationUsage={shouldShowLocationUsage} />
              <BusinessHoursRadioGroup />
              {hasCustomBusinessHours && <BusinessHoursFieldsTable />}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddLocationModal;
