import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { extractToAndState } from '@zen/utils/routing';

import Button from '../Button';
import { type ButtonVariant } from '../Button/types';
import TextLink from '../TextLink';
import Headline from '../Typography/Headline';
import ContainerImage from './container.svg?react';
import type { HelperLinkConfig, NoResultsButtonConfig } from './types';

export interface Props {
  button?: NoResultsButtonConfig;
  className?: string;
  headline: string;
  helperLink?: HelperLinkConfig;
  image?: ReactNode;
  isCentered?: boolean;
  secondaryButton?: NoResultsButtonConfig;
  showImage?: boolean;
  tagline?: ReactNode;
}

const NoResults: FC<Props> = (props) => {
  const {
    button,
    className = 'mt-16',
    headline,
    image = <ContainerImage className="w-20" data-testid="image" />,
    isCentered = true,
    secondaryButton,
    showImage = true,
    tagline,
    helperLink,
    ...rest
  } = props;

  const taglineClassnames: string = cx('flex justify-center mt-2 text-grey-base leading-normal text-sm max-w-[30rem] mx-auto', {
    'mb-6': !!button
  });

  const renderButton = (buttonConfig: NoResultsButtonConfig, variant: ButtonVariant = 'primary'): ReactNode => {
    if (buttonConfig.linkTo) {
      return (
        <Link onClick={buttonConfig.onClick} {...extractToAndState(buttonConfig.linkTo)}>
          <Button variant={variant}>{buttonConfig.label}</Button>
        </Link>
      );
    }

    return (
      <Button onClick={buttonConfig.onClick} variant={variant}>
        {buttonConfig.label}
      </Button>
    );
  };

  return (
    <div
      className={`flex flex-col text-center justify-center py-12 ${isCentered ? 'h-full' : className}`}
      data-testid="no-results"
      {...rest}
    >
      <div>
        {showImage && <div className="flex items-start justify-center mb-9">{image}</div>}
        <Headline level={3}>{headline}</Headline>
        {tagline && (
          <div className={taglineClassnames} data-testid="tagline">
            {tagline}
          </div>
        )}

        <div className="flex justify-center space-x-2">
          {button && renderButton(button)}
          {secondaryButton && renderButton(secondaryButton, 'secondary')}
        </div>
        {helperLink && (
          <div className="mt-6" data-testid="helper-link">
            <TextLink linkTo={helperLink.linkTo}>{helperLink.text}</TextLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoResults;
