import copy from 'copy-to-clipboard';
import type { ReactNode } from 'react';

import { ContextMenu, IconButton, Pill } from '@zen/DesignSystem';
import type { TableColumn } from '@zen/DesignSystem/types';
import type { IconName } from '@zen/Styleguide';

import { type AccountConnectionsSummary, AccountStatus } from '../types';
import DeleteConnectionButton from './DeleteConnectionButton';
import { connectionTypeMapping, statusMapping } from './helpers';

const getColumnsConfiguration = (
  accountId: string,
  onIdCopied: () => void,
  onInviteConnection: (account: AccountConnectionsSummary) => void,
  onUpdateName: (connectionAccountId: string, tradingName: string) => void,
  canDeleteConnections: boolean,
  canInviteConnections: boolean
): TableColumn<AccountConnectionsSummary>[] => {
  return [
    {
      key: 'company',
      title: 'Company',
      render: (_, { account }: AccountConnectionsSummary): ReactNode => {
        return <div>{account.tradingName}</div>;
      },
      sortable: false
    },
    {
      key: 'connectionType',
      title: 'Type',
      render: (_, { connectionType }: AccountConnectionsSummary): ReactNode => {
        return connectionTypeMapping[connectionType];
      },
      sortable: false
    },
    {
      key: 'status',
      title: '',
      render: (_, { account }: AccountConnectionsSummary): ReactNode => {
        const { label, pillType } = statusMapping[account.status];

        return <Pill type={pillType}>{label}</Pill>;
      },
      sortable: false,
      width: '10%'
    },
    {
      key: 'actions',
      fixed: 'right',
      render: (_, accountConnectionSummary: AccountConnectionsSummary): ReactNode => {
        const { account, connectionType } = accountConnectionSummary;
        const { id, tradingName, status } = account;
        const isManagedAccount = status === AccountStatus.MANAGED;
        const canInvite = canInviteConnections && isManagedAccount;
        const canUpdateName = canInvite;

        const handleCopyId = () => {
          copy(id);
          onIdCopied();
        };

        const items = [
          { icon: 'zicon-copy' as IconName, label: 'Copy account ID', onClick: handleCopyId },
          ...(canUpdateName
            ? [
                {
                  label: 'Edit name',
                  icon: 'zicon-edit' as IconName,
                  onClick: () => onUpdateName(account.id, tradingName)
                }
              ]
            : []),
          ...(canInvite
            ? [
                {
                  label: 'Invite connection',
                  icon: 'zicon-add-user' as IconName,
                  onClick: () => onInviteConnection(accountConnectionSummary)
                }
              ]
            : [])
        ];

        const renderActions = () => {
          const shouldRenderAction = items.length > 0;
          const shouldRenderContextMenu = items.length > 1;

          if (!shouldRenderAction) {
            return null;
          }

          if (shouldRenderContextMenu) {
            return <ContextMenu inline={false} items={items} togglerSize="medium" />;
          }

          return (
            <IconButton icon={items[0].icon} onClick={items[0].onClick} size="medium" title={items[0].label} variant="ghost" />
          );
        };

        return (
          <div className="flex space-x-2">
            {renderActions()}
            {canDeleteConnections && (
              <DeleteConnectionButton
                canDeleteConnection={canDeleteConnections}
                connectionType={connectionType}
                sourceAccountId={accountId}
                status={status}
                targetAccountId={id}
                targetAccountName={tradingName}
              />
            )}
          </div>
        );
      },
      sortable: false
    }
  ];
};

export default getColumnsConfiguration;
