import type { FC } from 'react';

import { useUserGroupsOptions } from '@zen/Accounts/AccountDetails/components/FormAccountUserGroupsSelect/useUserGroupsOptions';
import { Form, FormButtons, FormInput, FormRadioGroup, FormSelect } from '@zen/Components';
import { Body, Headline } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performFormMutation } from '@zen/utils/performMutation';

import FormEnterpriseConnectionSelect from '../FormEnterpriseConnectionSelect';
import GenerateKeyPanel from '../GenerateKeyPanel';
import { useEditSsoConfigurationMutation } from '../graphql';
import { provisionTypeOptions } from '../helpers';
import type { CreateSsoConfigurationInput, ProvisionMethod, SSOKeyFormValues } from '../types';
import { validationSchema } from './ssoKeyForm.validation';

interface Props {
  accountId: string;
  accountName: string;
  canEdit: boolean;
  initialValues: SSOKeyFormValues;
  isSSOEnabled: boolean;
}

interface HandleUpdateSsoConfiguration {
  connectionName: string;
  defaultRole: string;
  provisionMethod: ProvisionMethod;
}

const SSOKeyForm: FC<Props> = (props) => {
  const { accountId, accountName, canEdit, initialValues, isSSOEnabled } = props;
  const [editSsoConfiguration] = useEditSsoConfigurationMutation({ refetchQueries: ['getAccountDetails'] });

  const accountUserGroups = useUserGroupsOptions(accountId);

  const { addSuccess } = useNotification();

  const handleUpdateSsoConfiguration = (values: HandleUpdateSsoConfiguration) => {
    const { connectionName, defaultRole, provisionMethod } = values;
    const newSsoConfig: CreateSsoConfigurationInput = {
      accountId,
      connectionName,
      defaultRole,
      provisionMethod
    };

    return performFormMutation({
      mutationFn: () => editSsoConfiguration({ variables: { input: newSsoConfig } }),
      onSuccess: () => addSuccess('SSO Configuration updated.')
    });
  };

  return (
    <Form
      buttonText="Enable SSO"
      enableReinitialize={true}
      formButtons={() => null}
      initialValues={initialValues}
      onSubmit={handleUpdateSsoConfiguration}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => {
        const { provisionMethod } = values;
        const formIsValid: boolean = validationSchema.isValidSync(values);
        const isScimProvisioned: boolean = provisionMethod === 'SCIM';

        const buttonText: string = isSSOEnabled ? 'Save SSO configuration' : 'Enable SSO';
        const hasProvisionMethod: boolean = provisionMethod !== null;

        return (
          <>
            <Headline level={4}>Enable SSO for {accountName} </Headline>
            <Body className="max-w-xl my-2 text-grey-base">Select a connection to connect Zencargo with {accountName}</Body>
            <div className="grid grid-cols-8 col-span-12 gap-8 xl:gap-6 xl:grid-cols-12 gap-y-0">
              <div className="col-span-4">
                <FormRadioGroup
                  disabled={!canEdit}
                  label="SSO type"
                  name="provisionMethod"
                  options={provisionTypeOptions}
                  radioAlignment="column"
                />
                {hasProvisionMethod && (
                  <>
                    <FormEnterpriseConnectionSelect isDisabled={!canEdit && isSSOEnabled} />
                    <FormSelect
                      className="mb-4"
                      isSearchable={true}
                      label="Default role"
                      name="defaultRole"
                      options={accountUserGroups}
                      renderMenuInPortal={true}
                    />
                    <FormInput disabled={true} label="Allowed email address domains" name="domains" />
                  </>
                )}
              </div>
            </div>
            {canEdit && <FormButtons isSubmitting={isSubmitting} text={buttonText} />}
            {canEdit && isScimProvisioned && (
              <GenerateKeyPanel accountId={accountId} isKeyCreationEnabled={canEdit && formIsValid && isSSOEnabled} />
            )}
          </>
        );
      }}
    </Form>
  );
};

export default SSOKeyForm;
export type { Props as SSOKeyFormProps };
