import copy from 'copy-to-clipboard';
import type { ReactNode } from 'react';

import TableLink from '@zen/Components/TableLink';
import { IconButton, Pill } from '@zen/DesignSystem';
import type { TableColumn } from '@zen/DesignSystem/types';

import type { AccountLocationSummary } from '../types';
import AccountLocationsActions from './AccountLocationsActions';

interface ColumConfiguration {
  accountIsArchived: boolean;
  pathname: string;
}

const getColumnsConfiguration = ({ accountIsArchived, pathname }: ColumConfiguration): TableColumn<AccountLocationSummary>[] => {
  return [
    {
      key: 'name',
      title: 'Name',
      render: (_, { account, addressLabel, name, id: locationId }: AccountLocationSummary): ReactNode => {
        return (
          <div>
            <div>
              <TableLink linkTo={`${pathname}/${locationId}`}>{name || account.tradingName}</TableLink>
            </div>

            <div className="overflow-hidden text-xs truncate text-grey-base">{addressLabel}</div>
          </div>
        );
      }
    },
    {
      key: 'isRegisteredLegalAddress',
      sortable: false,
      title: '',
      render: (_, { isRegisteredLegalAddress }: AccountLocationSummary): ReactNode => {
        if (!isRegisteredLegalAddress) {
          return null;
        }

        return (
          <Pill className="capitalize" type="pending">
            HQ
          </Pill>
        );
      },
      width: 80
    },
    {
      key: 'tradingName',
      title: 'Linked to',
      render: (_, { account }: AccountLocationSummary): ReactNode => {
        return account.tradingName;
      }
    },
    {
      key: 'status',
      title: 'Status',
      sortable: false,
      render: (_, { isArchived }: AccountLocationSummary): ReactNode => {
        if (isArchived) {
          return <Pill type="problem">Archived</Pill>;
        }

        return <Pill type="success">Active</Pill>;
      }
    },
    {
      key: 'actions',
      fixed: 'right',
      render: (_, { account, id, isRegisteredLegalAddress, name, permissions }: AccountLocationSummary): ReactNode => {
        return (
          <>
            {!accountIsArchived && (
              <AccountLocationsActions
                accountId={account.id}
                accountName={account.tradingName}
                canArchiveLocation={!!permissions?.canArchive?.value}
                canEdit={!!permissions?.canEditDetails?.value}
                canUnarchiveLocation={!!permissions?.canUnarchive?.value}
                isRegisteredLegalAddress={isRegisteredLegalAddress}
                locationId={id}
                locationName={name}
              />
            )}
            {accountIsArchived && (
              <IconButton icon="zicon-copy" onClick={() => copy(id)} size="medium" title="Copy location ID" variant="ghost" />
            )}
          </>
        );
      },
      sortable: false
    }
  ];
};

export default getColumnsConfiguration;
