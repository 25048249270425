import { omit } from 'lodash';

import { getDefaultLatLng } from '@zen/Accounts/AccountForms/helpers';
import type { Option, SelectOption } from '@zen/DesignSystem/types';
import type { EntitledAccountsPayload } from '@zen/graphql/types.generated';

import type { CreateAccountLocationFormValues, CreateAccountLocationInput } from './types';

const getEntitledManageableAccountOptions = (entitledAccountsPayload: EntitledAccountsPayload): SelectOption<string>[] => {
  const account = entitledAccountsPayload?.account;
  const businessUnits = entitledAccountsPayload?.businessUnits || [];
  const managed = entitledAccountsPayload?.managed || [];
  const unmanaged = entitledAccountsPayload?.unmanaged || [];

  const accountOption = getAccountOption(account?.tradingName || '', account?.id || '');

  const businessUnitOptions = (): SelectOption<string> => {
    return {
      label: 'Business units',
      options: businessUnits?.map((businessUnit) => getAccountOption(businessUnit.tradingName, businessUnit.id))
    };
  };

  const managedAccountOptions = (): SelectOption<string> => {
    return {
      label: 'Managed connections',
      options: managed?.map((managedAccount) => getAccountOption(managedAccount.tradingName, managedAccount.id))
    };
  };

  const unmanagedAccountOptions = (): SelectOption<string> => {
    return {
      label: 'Unmanaged connections',
      options: unmanaged?.map((unmanagedAccount) => getAccountOption(unmanagedAccount.tradingName, unmanagedAccount.id))
    };
  };

  return [accountOption, businessUnitOptions(), managedAccountOptions(), unmanagedAccountOptions()];
};

const getAccountOption = (tradingName: string, id: string): Option<string> => {
  return {
    label: tradingName,
    value: id
  };
};

const prepareValues = (accountId: string, formValues: CreateAccountLocationFormValues): CreateAccountLocationInput => {
  const { accountId: selectedAccountId, businessHoursOption, shippingLocationAddress, geolocation, ...rest } = formValues;

  return {
    accountId: selectedAccountId || accountId,
    location: {
      ...rest,
      geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng(),
      address: shippingLocationAddress
    }
  };
};

export { getEntitledManageableAccountOptions, prepareValues };
