import { get } from 'lodash';
import type { FC } from 'react';

import { updateLocationAddressValidation } from '@zen/Accounts/AccountForms/CreateAccountForm/createAccountForm.validation';
import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import ShippingLocationFields from '@zen/Accounts/components/forms/ShippingLocationFields';
import type { UpdateAccountProps } from '@zen/Accounts/components/forms/types';
import type { FormInstance } from '@zen/Components/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateLocationAddressMutation } from '../../graphql';
import type { ShippingAddressFormValues } from '../types';
import { prepareValues } from './helpers';

type Props = UpdateAccountProps<ShippingAddressFormValues, 'locationId'>;

const UpdateAddress: FC<Props> = ({ onCancel, onSuccess, initialValues }) => {
  const [updateLocationAddress] = useUpdateLocationAddressMutation({ refetchQueries: ['getAccountLocation'] });
  const { addError, addSuccess } = useNotification();

  const handleUpdateAccount = async (values: ShippingAddressFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateLocationAddress({
          variables: {
            input: prepareValues(values)
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'There was a problem updating the address.'));
      },
      onSuccess: () => addSuccess('The address was updated.')
    });
  };

  return (
    <AccountFormWrapper<ShippingAddressFormValues>
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleUpdateAccount}
      onSuccess={onSuccess}
      validationSchema={updateLocationAddressValidation}
    >
      {(form: FormInstance<ShippingAddressFormValues>) => {
        const { values } = form;

        const { shippingLocationAddress, geolocation } = values;

        return (
          <ShippingLocationFields
            address={shippingLocationAddress}
            geolocation={geolocation}
            noMargin={true}
            showLocationDetails={false}
          />
        );
      }}
    </AccountFormWrapper>
  );
};

export default UpdateAddress;
export type { Props as UpdateAddressProps };
