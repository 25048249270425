import type { FC } from 'react';

import EmptyState from '../EmptyState';

const InactiveAccountLogin: FC = () => {
  return (
    <div className="flex flex-col text-center justify-center py-12 h-screen bg-white" data-testid="no-results">
      <EmptyState
        illustration={
          <img
            alt="Zencargo"
            className="w-40"
            data-testid="zencargo-image"
            src="https://assets.zencargo.com/assets/zencargo-logo.svg"
          />
        }
        primaryButton={{
          onClick: () => {
            window.open('mailto:sales@zencargo.com');
          },
          title: 'Contact sales'
        }}
        subTitle="Your organisation's account has been deactivated, so you can't log in. Get in touch to reactivate access."
        title="Your company's account is inactive"
      />
    </div>
  );
};

export default InactiveAccountLogin;
