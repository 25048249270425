import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateProductAttributeMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCoreProductAttributeInput;
}>;

export type UpdateProductAttributeMutationResult = { __typename?: 'Mutation' } & {
  updateCoreProductAttribute: { __typename?: 'UpdateCoreProductAttributePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
  };
};

export const UpdateProductAttributeDocument = /* #__PURE__ */ gql`
  mutation updateProductAttribute($input: UpdateCoreProductAttributeInput!) {
    updateCoreProductAttribute(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateProductAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateProductAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductAttributeMutation, { data, loading, error }] = useUpdateProductAttributeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductAttributeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductAttributeMutationResult, UpdateProductAttributeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateProductAttributeMutationResult, UpdateProductAttributeMutationVariables>(
    UpdateProductAttributeDocument,
    options
  );
}
export type UpdateProductAttributeMutationHookResult = ReturnType<typeof useUpdateProductAttributeMutation>;
