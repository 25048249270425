import { get } from 'lodash';
import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useArchiveAccountConnectionMutation } from '../../graphql';
import { type AccountConnectionTypeEnum, AccountStatus } from '../../types';

interface Props {
  connectionType: AccountConnectionTypeEnum;
  isOpen: boolean;
  onClose: () => void;
  sourceAccountId: string;
  status: AccountStatus;
  targetAccountId: string;
  targetAccountName: string;
}

const DeleteConnectionModal: FC<Props> = (props) => {
  const { isOpen, onClose, connectionType, sourceAccountId, status, targetAccountId, targetAccountName } = props;
  const { addError, addSuccess } = useNotification();
  const [archiveConnection, { loading }] = useArchiveAccountConnectionMutation();

  const configureModalCopy = () => {
    switch (status) {
      case AccountStatus.PENDING:
        return {
          confirmLabel: 'Revoke invite',
          header: 'Revoke invitation?',
          message: 'Are you sure you want to revoke the invitation? This action will cancel their invite to join the platform.',
          errorMessage: 'The invitation could not be revoked.',
          successMessage: 'Invitation was successfully revoked.'
        };
      default:
        return {
          confirmLabel: 'Delete',
          header: `Delete '${targetAccountName}'?`,
          message: `Are you sure you want to remove the connection to '${targetAccountName}'?`,
          errorMessage: 'The connection could not be deleted.',
          successMessage: 'Connection was successfully deleted.'
        };
    }
  };

  const { confirmLabel, header, message, errorMessage, successMessage } = configureModalCopy();

  const handleArchiveConnection = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        archiveConnection({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountDetails', 'getAccountConnections'],
          variables: {
            input: {
              connectionType,
              sourceAccountId,
              targetAccountId
            }
          }
        }),
      onError: (errors) =>
        addError(get(errors, '[0].message', errorMessage), {
          label: 'Try again',
          onClick: () => handleArchiveConnection()
        }),
      onSuccess: () => {
        addSuccess(successMessage);
        onClose();
      }
    });
  };

  return (
    <Dialog
      confirmLabel={confirmLabel}
      header={header}
      isLoading={loading}
      isOpen={isOpen}
      message={message}
      onClose={onClose}
      onConfirm={handleArchiveConnection}
    />
  );
};

export default DeleteConnectionModal;
export type { Props as DeleteConnectionModalProps };
