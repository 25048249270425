import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CoreProductCategory } from '../../types';
import { validationSchema } from './productCategoryForm.validation';

interface Props {
  buttonText: string;
  initialValues?: Pick<CoreProductCategory, 'name'>;
  onCancel: () => void;
  onSubmit: (values: { name: string }) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const ProductCategoryForm: FC<Props> = (props) => {
  const { buttonText, initialValues, onCancel, onSubmit, onSuccess } = props;

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text={buttonText}>
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      formName="ProductCategoryForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {() => <FormInput label="Category name" name="name" />}
    </Form>
  );
};

export default ProductCategoryForm;
