import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { Role } from '@zen/Networks/types';

import SidebarLinks from '../components/SidebarLinks';
import type { SidebarCounters } from '../types';
import { getConfig } from './config';
import type { CustomerLinksFeatureFlags } from './types';

interface Props {
  counters: SidebarCounters;
}

const CustomerLinks: FC<Props> = ({ counters }) => {
  const isFreightSpendEnabled: boolean = useFeatureIsOn('freight-spend');
  const isRateCardsEnabled: boolean = useFeatureIsOn('rate-cards');
  const isUtilisationEnabled: boolean = useFeatureIsOn('utilisation');
  const role = useRole();
  const { check } = useGlobalPermissions();
  const canViewApiConsole = check('apiConsole.canAccessRoute') || role === Role.AGENT_FORWARDER;
  const canAccessFreightSpend = check('insights.freightSpendCanAccessRoute');

  const featureFlags: CustomerLinksFeatureFlags = {
    isFreightSpendEnabled,
    isRateCardsEnabled,
    isUtilisationEnabled
  };

  const config = getConfig(role, featureFlags, canViewApiConsole, canAccessFreightSpend);

  return <SidebarLinks config={config} counters={counters} />;
};

export default CustomerLinks;
