import { AccountType, type CurrentUserResponse, type UserProfile } from './types';

export const getAccountInformation = ({ account }: CurrentUserResponse) => {
  if (account) {
    const { parentAccountId, tradingName, type } = account;
    const isDivision: boolean = type === AccountType.DIVISION || false;

    return {
      division: isDivision && parentAccountId ? { name: tradingName, parentAccountId } : null,
      isDivision,
      isParent: account?.type === AccountType.PARENT || false,
      registeredCompanyName: account?.tradingName || ''
    };
  }

  return {
    division: null,
    isDivision: false,
    isParent: false,
    registeredCompanyName: ''
  };
};

export const getUserInformation = ({ currentUserDetails, currentUser: legacyUser }: CurrentUserResponse) => {
  if (currentUserDetails) {
    const { email, firstName, id, isProfileCompleted, lastName, phoneNumber, account } = currentUserDetails;

    return {
      accountUuid: account?.parentAccountId || account?.id || '',
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      organizationalRole: account.organizationalRole,
      phoneNumber: phoneNumber || '',
      uuid: id || '',
      role: null,
      isSingleSignOn: account.ssoDetails.enabled || false,
      isProfileCompleted
    };
  }

  return {
    accountUuid: '',
    firstName: legacyUser?.profile?.firstName || '',
    lastName: legacyUser?.profile?.lastName || '',
    email: legacyUser?.profile?.email || '',
    organizationalRole: null,
    phoneNumber: legacyUser?.profile?.phoneNumber || '',
    uuid: legacyUser?.uuid || '',
    role: null,
    isSingleSignOn: false,
    isProfileCompleted: !!legacyUser?.profile?.firstName && !!legacyUser?.profile?.lastName
  };
};

export const prepareUserProfile = (values: CurrentUserResponse): UserProfile => {
  return {
    ...values.authorization,
    ...getUserInformation(values),
    ...getAccountInformation(values)
  };
};

export const saveUserData = ({ division, isAdmin, isParent, registeredCompanyName, uuid }: UserProfile) => {
  window.localStorage.setItem('accountName', registeredCompanyName);
  window.localStorage.setItem('isAdmin', `${isAdmin}`);
  window.localStorage.setItem('isDivision', `${!!division}`);
  window.localStorage.setItem('isParent', `${!!isParent}`);
  window.localStorage.setItem('userId', uuid);
};
