import type { RadioGroupOption } from '@zen/Components/RadioGroup/types';
import { ProvisionMethod } from '@zen/graphql/types.generated';

export const provisionTypeOptions: RadioGroupOption<ProvisionMethod>[] = [
  {
    description: 'All users within the customers directory to access the platform.',
    label: 'Auto-provisioning',
    value: ProvisionMethod.AUTO
  },
  {
    description: 'Customer can control which users in their directory may access the platform.',
    label: 'SCIM-provisioning',
    value: ProvisionMethod.SCIM
  }
];
