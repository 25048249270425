import cx from 'classnames';
import copy from 'copy-to-clipboard';
import pluralize from 'pluralize';
import type { ReactNode } from 'react';

import Avatar from '@zen/Components/Avatar';
import { IconButton } from '@zen/DesignSystem';
import type { TableColumn } from '@zen/DesignSystem/types';

import type { UserGroupOption } from '../components/FormAccountUserGroupsSelect/useUserGroupsOptions';
import type { AccountMember } from '../types';
import AssignBusinessUnitsButton from './AssignBusinessUnitsButton';
import AssignMemberUserGroupButton from './AssignMemberUserGroupButton';
import DeleteMemberModalButton from './DeleteMemberModalButton';

interface Params {
  accountHasBusinessUnits: boolean;
  accountId: string;
  accountUserGroups: UserGroupOption[];
  canEdit: boolean;
  onDelete: () => void;
}

const getColumnsConfiguration = ({
  accountHasBusinessUnits,
  accountId,
  accountUserGroups,
  canEdit,
  onDelete
}: Params): TableColumn<AccountMember>[] => {
  const fullNameColumn: TableColumn<AccountMember> = {
    key: 'fullName',
    title: 'Name',
    render: (_, { firstName, fullName, email }: AccountMember): ReactNode => {
      const className = cx({ 'text-xs': firstName, 'text-sm': !firstName }, 'text-grey-base');

      return (
        <div className="flex items-center space-x-2">
          <Avatar firstName={firstName} size="small" />
          <div>
            {fullName && <div>{fullName}</div>}
            <div className={className}>{email}</div>
          </div>
        </div>
      );
    }
  };

  const businessUnitsColumn: TableColumn<AccountMember> = {
    key: 'businessUnits',
    title: 'Business units',
    render: (_, { businessUnits, id: memberId, assignedRoles }: AccountMember): ReactNode => {
      const isAdminRole: boolean = assignedRoles.map(({ name }) => name).includes('Admin');

      const buttonText: string = businessUnits.length
        ? pluralize('business unit', businessUnits.length, true)
        : 'All business units';

      return (
        <AssignBusinessUnitsButton
          accountId={accountId}
          buttonText={buttonText}
          canAssignMemberBusinessUnits={canEdit}
          isAdminRole={isAdminRole}
          memberBusinessUnits={businessUnits}
          memberId={memberId}
        />
      );
    },
    sortable: false,
    width: 250
  };

  const permissionsColumn: TableColumn<AccountMember> = {
    key: 'permissions',
    title: 'Permissions',
    render: (_, { assignedRoles, id }: AccountMember): ReactNode => {
      return (
        <AssignMemberUserGroupButton
          accountId={accountId}
          accountUserGroups={accountUserGroups}
          assignedRole={assignedRoles[0]}
          canAssignMemberRole={canEdit}
          memberId={id}
        />
      );
    },
    sortable: false,
    width: 200
  };

  const actionsColumn: TableColumn<AccountMember> = {
    key: 'actions',
    fixed: 'right',
    render: (_, { id, fullName, isProfileCompleted }: AccountMember): ReactNode => {
      return (
        <div className="flex space-x-2">
          <IconButton icon="zicon-copy" onClick={() => copy(id)} size="medium" title="Copy user ID" variant="ghost" />
          {canEdit && (
            <DeleteMemberModalButton
              isProfileCompleted={isProfileCompleted}
              memberId={id}
              memberName={fullName}
              onSuccess={onDelete}
            />
          )}
        </div>
      );
    },
    sortable: false
  };

  return [fullNameColumn, ...(accountHasBusinessUnits ? [businessUnitsColumn] : []), permissionsColumn, actionsColumn];
};

export default getColumnsConfiguration;
