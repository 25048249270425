import copy from 'copy-to-clipboard';
import type { FC } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import TableLink from '@zen/Components/TableLink';
import { ContextMenu, IconButton } from '@zen/DesignSystem';
import type { MenuItemType } from '@zen/DesignSystem/types';

import ArchiveLocationModal from '../ArchiveLocationModal';
import UnarchiveLocationModal from '../UnarchiveLocationModal';
import UpdateAccountHeadquartersModal from '../UpdateAccountHeadquartersModal';

interface Props {
  accountId: string;
  accountName: string;
  canArchiveLocation: boolean;
  canEdit: boolean;
  canUnarchiveLocation: boolean;
  isRegisteredLegalAddress: boolean;
  locationId: string;
  locationName: string;
}

const AccountLocationsActions: FC<Props> = (props: Props) => {
  const { pathname } = useLocation();
  const {
    accountId,
    accountName,
    canArchiveLocation,
    canEdit,
    canUnarchiveLocation,
    isRegisteredLegalAddress,
    locationId,
    locationName
  } = props;

  const [isAssignHQModalOpen, setAssignHQModalOpen] = useState<boolean>(false);
  const [isArchiveLocationModalOpen, setIsArchiveLocationModalOpen] = useState<boolean>(false);
  const [isUnarchiveLocationModalOpen, setIsUnarchiveLocationModalOpen] = useState<boolean>(false);

  const archiveLocationOption: MenuItemType = {
    label: 'Archive',
    onClick: () => setIsArchiveLocationModalOpen(true),
    icon: 'zicon-archive'
  };
  const unarchiveLocationOption: MenuItemType = {
    label: 'Unarchive',
    onClick: () => setIsUnarchiveLocationModalOpen(true),
    icon: 'zicon-unarchive'
  };
  const makeHQOption: MenuItemType = {
    disabled: isRegisteredLegalAddress,
    disabledItemTooltip: isRegisteredLegalAddress ? 'This is already an HQ' : undefined,
    label: 'Make HQ',
    onClick: () => setAssignHQModalOpen(true),
    icon: 'zicon-hq'
  };

  const copyLocationIdOption: MenuItemType = {
    label: 'Copy location ID',
    onClick: () => copy(locationId),
    icon: 'zicon-copy'
  };

  const items: MenuItemType[] = [
    ...(canArchiveLocation ? [archiveLocationOption] : []),
    ...(canUnarchiveLocation ? [unarchiveLocationOption] : []),
    ...(canEdit ? [makeHQOption] : []),
    copyLocationIdOption
  ];

  const isContextMenuVisible: boolean = items.length > 0;

  const buttonTitle: string = canEdit ? 'Edit' : 'Unmanaged locations cannot be edited';

  return (
    <>
      <ArchiveLocationModal
        isOpen={isArchiveLocationModalOpen}
        locationId={locationId}
        locationName={locationName}
        onClose={() => setIsArchiveLocationModalOpen(false)}
      />
      <UnarchiveLocationModal
        isOpen={isUnarchiveLocationModalOpen}
        locationId={locationId}
        locationName={locationName}
        onClose={() => setIsUnarchiveLocationModalOpen(false)}
      />
      <UpdateAccountHeadquartersModal
        accountId={accountId}
        accountName={accountName}
        isOpen={isAssignHQModalOpen}
        locationId={locationId}
        onClose={() => setAssignHQModalOpen(false)}
      />
      <div className="flex space-x-2">
        <TableLink linkTo={`${pathname}/${locationId}`}>
          <IconButton disabled={!canEdit} icon="zicon-edit" size="medium" title={buttonTitle} variant="ghost" />
        </TableLink>
        {isContextMenuVisible && <ContextMenu inline={false} items={items} togglerSize="medium" />}
      </div>
    </>
  );
};

export default AccountLocationsActions;
