import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetEntitledAccountsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  selectedAccountId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetEntitledAccountsQueryResult = { __typename?: 'Query' } & {
  entitledAccounts?: GraphQLTypes.Maybe<
    { __typename?: 'EntitledAccountsPayload' } & {
      account?: GraphQLTypes.Maybe<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
      businessUnits: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
      managed: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
    }
  >;
};

export const GetEntitledAccountsDocument = /* #__PURE__ */ gql`
  query getEntitledAccounts($accountId: String!, $searchQuery: String, $selectedAccountId: String) {
    entitledAccounts(accountId: $accountId, searchQuery: $searchQuery, selectedAccountId: $selectedAccountId) {
      account {
        id
        tradingName
      }
      businessUnits {
        id
        tradingName
      }
      managed {
        id
        tradingName
      }
    }
  }
`;

/**
 * __useGetEntitledAccountsQuery__
 *
 * To run a query within a React component, call `useGetEntitledAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitledAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitledAccountsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      searchQuery: // value for 'searchQuery'
 *      selectedAccountId: // value for 'selectedAccountId'
 *   },
 * });
 */
export function useGetEntitledAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEntitledAccountsQueryResult, GetEntitledAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetEntitledAccountsQueryResult, GetEntitledAccountsQueryVariables>(GetEntitledAccountsDocument, options);
}
export function useGetEntitledAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEntitledAccountsQueryResult, GetEntitledAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetEntitledAccountsQueryResult, GetEntitledAccountsQueryVariables>(
    GetEntitledAccountsDocument,
    options
  );
}
export type GetEntitledAccountsQueryHookResult = ReturnType<typeof useGetEntitledAccountsQuery>;
export type GetEntitledAccountsLazyQueryHookResult = ReturnType<typeof useGetEntitledAccountsLazyQuery>;
