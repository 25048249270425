import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import ProductAttributeForm from '../components/ProductAttributeForm';
import { useCreateProductAttributeMutation } from '../graphql';
import type { CoreProductAttribute } from '../types';

interface Props {
  accountId: string;
  onClose: () => void;
}

const CreateProductAttribute: FC<Props> = ({ accountId, onClose }) => {
  const { addSuccess, addError } = useNotification();
  const [createAttribute] = useCreateProductAttributeMutation();

  const handleSubmit = async ({ name, type }: Pick<CoreProductAttribute, 'name' | 'type'>): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        createAttribute({
          awaitRefetchQueries: true,
          refetchQueries: ['productAttributes'],
          variables: {
            input: {
              accountId,
              name,
              type
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Product attribute added successfully.');
    onClose();
  };

  return <ProductAttributeForm buttonText="Add" onCancel={onClose} onSubmit={handleSubmit} onSuccess={handleSuccess} />;
};

export default CreateProductAttribute;
