import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountBusinessUnitsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  includeArchived?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetAccountBusinessUnitsQueryResult = { __typename?: 'Query' } & {
  accountBusinessUnits?: GraphQLTypes.Maybe<
    { __typename?: 'AccountConnection' } & Pick<GraphQLTypes.AccountConnection, 'totalCount'> & {
        nodes: Array<
          GraphQLTypes.Maybe<
            { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'referencePrefix' | 'tradingName' | 'type'>
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetAccountBusinessUnitsDocument = /* #__PURE__ */ gql`
  query getAccountBusinessUnits(
    $accountId: String!
    $includeArchived: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: SortInput
  ) {
    accountBusinessUnits(
      accountId: $accountId
      includeArchived: $includeArchived
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
    ) {
      nodes {
        id
        referencePrefix
        tradingName
        type
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetAccountBusinessUnitsQuery__
 *
 * To run a query within a React component, call `useGetAccountBusinessUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountBusinessUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountBusinessUnitsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      includeArchived: // value for 'includeArchived'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAccountBusinessUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountBusinessUnitsQueryResult, GetAccountBusinessUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountBusinessUnitsQueryResult, GetAccountBusinessUnitsQueryVariables>(
    GetAccountBusinessUnitsDocument,
    options
  );
}
export function useGetAccountBusinessUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountBusinessUnitsQueryResult, GetAccountBusinessUnitsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountBusinessUnitsQueryResult, GetAccountBusinessUnitsQueryVariables>(
    GetAccountBusinessUnitsDocument,
    options
  );
}
export type GetAccountBusinessUnitsQueryHookResult = ReturnType<typeof useGetAccountBusinessUnitsQuery>;
export type GetAccountBusinessUnitsLazyQueryHookResult = ReturnType<typeof useGetAccountBusinessUnitsLazyQuery>;
