import type { FC, ReactNode } from 'react';

import { getInitialFormValues } from '@zen/Accounts/AccountForms/helpers';
import BusinessHoursFieldsTable from '@zen/Accounts/components/forms/BusinessHoursFieldsTable';
import BusinessHoursRadioGroup from '@zen/Accounts/components/forms/BusinessHoursRadioGroup';
import ShippingLocationFields from '@zen/Accounts/components/forms/ShippingLocationFields';
import { Form, FormButtons, FormInput } from '@zen/Components';
import type { FormInstance } from '@zen/Components/types';
import { Button } from '@zen/DesignSystem';
import type { CreateManagedAccountInput } from '@zen/graphql/types.generated';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CreateManagedAccountFormInitialValues } from '../../AccountLocations/AddLocationModal/types';
import { createManagedAccountValidation } from './createManagedAccountForm.validation';
import type { CreateManagedAccountFormValues } from './types';

interface Props {
  onCancel: () => void;
  onSubmit: (values: CreateManagedAccountFormValues) => Promise<IOkOrErrorResult>;
}

const CreateManagedAccountForm: FC<Props> = ({ onCancel, onSubmit }) => {
  const renderFormButtons = ({ isSubmitting }: FormInstance<CreateManagedAccountInput>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add connection">
      <Button data-testid="cancel-button" onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const initialFormValues = getInitialFormValues();

  const initialValues: CreateManagedAccountFormInitialValues = {
    accountId: '',
    companyName: '',
    ...initialFormValues.shippingLocation,
    shippingLocationAddress: initialFormValues.shippingLocationAddress,
    businessHours: initialFormValues.businessHours,
    usageContext: null
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={createManagedAccountValidation}
    >
      {({ values }: FormInstance<CreateManagedAccountFormValues>) => {
        const { businessHoursOption, shippingLocationAddress } = values;
        const hasCustomBusinessHours: boolean = businessHoursOption === 'custom';

        return (
          <>
            <FormInput label="Company name" name="companyName" />
            <ShippingLocationFields address={shippingLocationAddress} autoFocusNameField={false} />
            <BusinessHoursRadioGroup />
            {hasCustomBusinessHours && <BusinessHoursFieldsTable />}
          </>
        );
      }}
    </Form>
  );
};

export default CreateManagedAccountForm;
export type { Props as CreateManagedAccountFormProps };
