import type { SortInput } from '@zen/types';
import { usePagination } from '@zen/utils/hooks/pagination';

import type { ProductAttributesQueryResult, ProductAttributesQueryVariables } from '../graphql';
import { useProductAttributesQuery } from '../graphql';
import { type CoreProductAttribute, CoreProductAttributeOrderInputField } from '../types';

const getSortBy = (order: SortInput | undefined) => {
  if (!order) return;

  const fieldConfig: { [key: string]: CoreProductAttributeOrderInputField } = {
    name: CoreProductAttributeOrderInputField.NAME
  };

  return {
    field: fieldConfig[order.field],
    direction: order.direction
  };
};
const useProductAttributes = (accountId: string, order: SortInput | undefined) => {
  return usePagination<ProductAttributesQueryResult, ProductAttributesQueryVariables, CoreProductAttribute>(
    useProductAttributesQuery,
    'coreProductAttributes',
    { accountId, order: getSortBy(order) },
    20,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
};

export default useProductAttributes;
