import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { BusinessHoursFragmentDoc } from '../../../graphql/fragments/BusinessHours.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountDetailsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetAccountDetailsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<
      GraphQLTypes.Account,
      | 'demoMode'
      | 'hasBusinessUnitsThatSupplyEachOther'
      | 'id'
      | 'organizationalRole'
      | 'referencePrefix'
      | 'status'
      | 'tradingName'
    > & {
        businessUnits: Array<
          { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'referencePrefix' | 'tradingName' | 'type'>
        >;
        legalInformation: { __typename?: 'AccountLegalInformation' } & Pick<
          GraphQLTypes.AccountLegalInformation,
          'companyRegistrationNumber' | 'eoriNumber' | 'registeredName' | 'vatIdentificationNumber'
        > & {
            registeredAddress: { __typename?: 'Address' } & Pick<
              GraphQLTypes.Address,
              'city' | 'countyOrState' | 'line1' | 'line2' | 'postalCodeOrZip'
            > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
          };
        permissions?: GraphQLTypes.Maybe<
          { __typename?: 'AccountPermissions' } & {
            settings?: GraphQLTypes.Maybe<
              { __typename?: 'AccountSettingsPermissions' } & {
                canCreateBusinessUnit?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canCreateConnection?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canCreateLocation?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canCreateManagedAccount?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canCreateMember?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canCreateWebhook?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canDeactivate?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canDeleteConnection?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canDeleteWebhook?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEditAccountDetails?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEditBusinessUnit?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEditDemoMode?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEditLegalDetails?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEditMember?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEditWebhook?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canEnableAuthentication?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canInviteConnection?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canLinkAgentToCustomer?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canLinkCustomerToAgent?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canLinkCustomerToSupplier?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canLinkSupplierToCustomer?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canViewConnections?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
                canViewWebhooks?: GraphQLTypes.Maybe<
                  { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                >;
              }
            >;
          }
        >;
        ssoDetails: { __typename?: 'SsoDetails' } & Pick<
          GraphQLTypes.SsoDetails,
          'connectionName' | 'defaultRole' | 'domains' | 'enabled' | 'provisionMethod' | 'uuid'
        > & { apiKeys: Array<{ __typename?: 'ScimApiKey' } & Pick<GraphQLTypes.ScimApiKey, 'expiryDate' | 'id' | 'status'>> };
      }
  >;
  accountLocations?: GraphQLTypes.Maybe<
    { __typename?: 'AccountLocationConnection' } & {
      nodes: Array<
        GraphQLTypes.Maybe<
          { __typename?: 'AccountLocation' } & Pick<
            GraphQLTypes.AccountLocation,
            'addressLabel' | 'id' | 'isRegisteredLegalAddress' | 'name' | 'usageContext'
          > & {
              address: { __typename?: 'Address' } & Pick<
                GraphQLTypes.Address,
                'city' | 'countyOrState' | 'line1' | 'line2' | 'postalCodeOrZip'
              > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
              businessHours?: GraphQLTypes.Maybe<
                { __typename?: 'BusinessHours' } & {
                  monday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  tuesday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  wednesday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  thursday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  friday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  saturday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  sunday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                }
              >;
              geolocation?: GraphQLTypes.Maybe<
                { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
              >;
            }
        >
      >;
    }
  >;
};

export const GetAccountDetailsDocument = /* #__PURE__ */ gql`
  query getAccountDetails($accountId: String!) {
    account(accountId: $accountId) {
      businessUnits {
        id
        referencePrefix
        tradingName
        type
      }
      demoMode
      hasBusinessUnitsThatSupplyEachOther
      id
      legalInformation {
        companyRegistrationNumber
        eoriNumber
        registeredAddress {
          city
          country {
            code
            name
          }
          countyOrState
          line1
          line2
          postalCodeOrZip
        }
        registeredName
        vatIdentificationNumber
      }
      organizationalRole
      permissions {
        settings {
          canCreateBusinessUnit {
            value
          }
          canCreateConnection {
            value
          }
          canCreateLocation {
            value
          }
          canCreateManagedAccount {
            value
          }
          canCreateMember {
            value
          }
          canCreateWebhook {
            value
          }
          canDeactivate {
            value
          }
          canDeleteConnection {
            value
          }
          canDeleteWebhook {
            value
          }
          canEditAccountDetails {
            value
          }
          canEditBusinessUnit {
            value
          }
          canEditDemoMode {
            value
          }
          canEditLegalDetails {
            value
          }
          canEditMember {
            value
          }
          canEditWebhook {
            value
          }
          canEnableAuthentication {
            value
          }
          canInviteConnection {
            value
          }
          canLinkAgentToCustomer {
            value
          }
          canLinkCustomerToAgent {
            value
          }
          canLinkCustomerToSupplier {
            value
          }
          canLinkSupplierToCustomer {
            value
          }
          canViewConnections {
            value
          }
          canViewWebhooks {
            value
          }
        }
      }
      referencePrefix
      ssoDetails {
        apiKeys {
          expiryDate
          id
          status
        }
        connectionName
        defaultRole
        domains
        enabled
        provisionMethod
        uuid
      }
      status
      tradingName
    }
    accountLocations(accountId: $accountId) {
      nodes {
        address {
          city
          country {
            code
            name
          }
          countyOrState
          line1
          line2
          postalCodeOrZip
        }
        addressLabel
        businessHours {
          ...BusinessHours
        }
        geolocation {
          latitude
          longitude
        }
        id
        isRegisteredLegalAddress
        name
        usageContext
      }
    }
  }
  ${BusinessHoursFragmentDoc}
`;

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
}
export function useGetAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
}
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
