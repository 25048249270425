import type { SortInput } from '@zen/types';
import { usePagination } from '@zen/utils/hooks/pagination';

import type { ProductCategoriesQueryResult, ProductCategoriesQueryVariables } from '../graphql';
import { useProductCategoriesQuery } from '../graphql';
import { type CoreProductCategory, CoreProductCategoryOrderInputField } from '../types';

const getSortBy = (order: SortInput | undefined) => {
  if (!order) return;

  const fieldConfig: { [key: string]: CoreProductCategoryOrderInputField } = {
    name: CoreProductCategoryOrderInputField.NAME
  };

  return {
    field: fieldConfig[order.field],
    direction: order.direction
  };
};

const useProductCategories = (accountId: string, order: SortInput | undefined) => {
  return usePagination<ProductCategoriesQueryResult, ProductCategoriesQueryVariables, CoreProductCategory>(
    useProductCategoriesQuery,
    'coreProductCategories',
    { accountId, order: getSortBy(order) },
    20,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
};

export default useProductCategories;
