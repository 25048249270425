import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import AccountLocation from '@zen/Accounts/AccountDetails/AccountLocation';
import AccountSettings from '@zen/Accounts/AccountSettings/AccountSettings';
import { StageValue } from '@zen/Booking/types';
import RouteAuthoriser from '@zen/Components/RouteAuthoriser';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import {
  accountSettingsRoutes,
  accountsRoutes,
  accrualRoutes,
  analyticsRoutes,
  apiConsoleRoutes,
  bookingRequestRoutes,
  consolidationRoutes,
  costTrackingRoutes,
  csvParserRoutes,
  dashboardRoutes,
  documentProcessorRoutes,
  documentRoutes,
  exchangeRatesRoutes,
  financeRoutes,
  intakePlanningRoutes,
  networks,
  newBookingRoutes,
  opsDashboardRoutes,
  opsQuoteRoutes,
  opsSettingsRoutes,
  opsShipmentRoutes,
  orderRoutes,
  packingListsRoutes,
  productRoutes,
  productSettingsRoutes,
  quotesRoutes,
  rateCardRoutes,
  requestBookingRoutes,
  rulesEngineRoutes,
  settingsRoutes,
  shipmentRoutes,
  taskListRoutes,
  utilisationRoutes,
  warehouseIntakeRoutes,
  workflowsRoutes
} from '@zen/Routes';
import loadable from '@zen/utils/component/loadable';
import useAccount from '@zen/utils/hooks/useAccount';

const Accruals = loadable(() => import('@zen/Accruals/Accruals'));

const Accounts = loadable(() => import('@zen/Accounts'));

const AnalyticsDashboards = loadable(() => import('@zen/AnalyticsDashboards'));
const APIConsole = loadable(() => import('../../../Components/APIConsole'));
const BookingDetails = loadable(() => import('@zen/Booking/BookingDetails'));
const BookingRequests = loadable(() => import('@zen/BookingRequests'));
const Consolidation = loadable(() => import('@zen/Consolidation'));
const CostTracking = loadable(() => import('@zen/CostTracking/CostTracking'));
const DashboardContainer = loadable(() => import('@zen/Dashboard'));
const DocumentParser = loadable(() => import('@zen/Orders/DocumentParser'));
const DocumentProcessor = loadable(() => import('@zen/DocumentProcessor'));
const DocumentsIndex = loadable(() => import('@zen/Shipments/Documents/DocumentIndex'));
const Finance = loadable(() => import('@zen/Finance'));
const IntakePlanningContainer = loadable(() => import('@zen/IntakePlanning'));
const LegacyBookingRequests = loadable(() => import('@zen/LegacyBookingRequests'));
const ProductsContainer = loadable(() => import('@zen/Products'));
const LegacyProductsContainer = loadable(() => import('@zen/LegacyProducts'));
const ManageExchangeRates = loadable(() => import('@zen/ManageExchangeRates'));
const NetworksContainer = loadable(() => import('@zen/Networks'));
const NewBookingContainer = loadable(() => import('@zen/Booking/NewBooking/NewBookingContainer'));
const OperationsDashboardContainer = loadable(() => import('@zen/OperationsDashboard'));
const OperationsQuotesContainer = loadable(() => import('@zen/OperationsQuotes'));
const OperationsSettingsContainer = loadable(() => import('@zen/OperationsSettings'));
const OperationsShipmentsContainer = loadable(() => import('@zen/OperationsShipments'));
const OrdersContainer = loadable(() => import('@zen/Orders'));
const PackingListsContainer = loadable(() => import('@zen/PackingLists'));
const ProductSettingsContainer = loadable(() => import('@zen/ProductSettings'));
const QuotesIndex = loadable(() => import('@zen/Quotes/QuotesIndex'));
const RateCards = loadable(() => import('@zen/RateCards/RateCardsContainer'));
const RequestBookingContainer = loadable(() => import('@zen/Booking/NewBooking/RequestBookingContainer'));
const RulesEngineContainer = loadable(() => import('@zen/RulesEngine'));
const SettingsContainer = loadable(() => import('@zen/Settings'));
const Shipments = loadable(() => import('@zen/Shipments'));
const TaskListContainer = loadable(() => import('@zen/Tasks/TaskListContainer'));
const Utilisation = loadable(() => import('@zen/Utilisation'));
const WarehouseIntake = loadable(() => import('@zen/WarehouseIntake'));
const WorkflowsContainer = loadable(() => import('@zen/Workflows'));
const ZencargoNetwork = loadable(() => import('@zen/Networks/ZencargoNetwork'));

const DashboardRoutes: FC = () => {
  const { accountUuid, parentAccountId } = useAccount();
  const isNewBookingRequestFeatureOn = useFeatureIsOn('new-booking-request');
  const isNewProductsFeatureOn = useFeatureIsOn('new-products');

  const customerNetworksRoutes = networks('customer');
  const zencargoNetworksRoutes = networks('zencargo');

  return (
    <div className="h-full" data-testid="dashboard-routes">
      <Routes>
        <Route
          element={
            <RouteAuthoriser path={quotesRoutes.quoteDetailsPage.path}>
              <BookingDetails activityFeedViewType={StageValue.QUOTE_REQUESTED} />
            </RouteAuthoriser>
          }
          path={quotesRoutes.quoteDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={quotesRoutes.quoteIndex.path}>
              <QuotesIndex />
            </RouteAuthoriser>
          }
          path={quotesRoutes.quoteIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={shipmentRoutes.shipmentDetailsPage.path}>
              <BookingDetails />
            </RouteAuthoriser>
          }
          path={shipmentRoutes.shipmentDetailsPage.relativePath}
        />
        <Route element={<CostTracking />} path={costTrackingRoutes.index.relativePath} />
        <Route
          element={
            <RouteAuthoriser path={exchangeRatesRoutes.manageExchangeRatesIndex.path}>
              <ManageExchangeRates />
            </RouteAuthoriser>
          }
          path={exchangeRatesRoutes.manageExchangeRatesIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={shipmentRoutes.shipmentIndex.path}>
              <Shipments />
            </RouteAuthoriser>
          }
          path={shipmentRoutes.shipmentIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={documentRoutes.index.path}>
              <DocumentsIndex />
            </RouteAuthoriser>
          }
          path={documentRoutes.index.relativePath}
        />
        <Route element={<DocumentProcessor />} path={documentProcessorRoutes.index.relativePath} />
        <Route
          element={
            <RouteAuthoriser path={accrualRoutes.index.path}>
              <Accruals />
            </RouteAuthoriser>
          }
          path={accrualRoutes.index.relativePath}
        />
        <Route element={<PackingListsContainer />} path={packingListsRoutes.index.relativePath} />
        <Route
          element={
            <RouteAuthoriser path={bookingRequestRoutes.manufacturerRequestsDetailsPage.path}>
              <BookingDetails activityFeedViewType={StageValue.BOOKING_REQUESTED} />
            </RouteAuthoriser>
          }
          path={bookingRequestRoutes.manufacturerRequestsDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={bookingRequestRoutes.readyForApprovalDetailsPage.path}>
              <BookingDetails activityFeedViewType={StageValue.BOOKING_REQUESTED} />
            </RouteAuthoriser>
          }
          path={bookingRequestRoutes.readyForApprovalDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={bookingRequestRoutes.rejectedDetailsPage.path}>
              <BookingDetails activityFeedViewType={StageValue.BOOKING_REQUESTED} />
            </RouteAuthoriser>
          }
          path={bookingRequestRoutes.rejectedDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={bookingRequestRoutes.requestIndex.path}>
              {isNewBookingRequestFeatureOn ? <BookingRequests /> : <LegacyBookingRequests />}
            </RouteAuthoriser>
          }
          path={bookingRequestRoutes.requestIndex.relativePath}
        />
        <Route element={<SettingsContainer />} path={settingsRoutes.index.relativePath} />
        <Route element={<AnalyticsDashboards />} path={analyticsRoutes.index.relativePath} />
        <Route
          element={
            <RouteAuthoriser path={customerNetworksRoutes.myOrganisation.index.path}>
              <NetworksContainer networkId={accountUuid} />
            </RouteAuthoriser>
          }
          path={customerNetworksRoutes.myOrganisation.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={orderRoutes.orderDetailsPage.path}>
              <BookingDetails />
            </RouteAuthoriser>
          }
          path={orderRoutes.orderDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={orderRoutes.index.path}>
              <OrdersContainer />
            </RouteAuthoriser>
          }
          path={orderRoutes.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={intakePlanningRoutes.index.path}>
              <IntakePlanningContainer />
            </RouteAuthoriser>
          }
          path={intakePlanningRoutes.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={productRoutes.index.path}>
              {isNewProductsFeatureOn ? <ProductsContainer /> : <LegacyProductsContainer />}
            </RouteAuthoriser>
          }
          path={productRoutes.index.relativePath}
        />
        <Route element={<NewBookingContainer />} path={newBookingRoutes.newBookingFromZencargoReference.relativePath} />
        <Route element={<RequestBookingContainer />} path={requestBookingRoutes.requestBooking.relativePath} />
        <Route element={<APIConsole accountUuid={accountUuid} />} path={apiConsoleRoutes.index.relativePath} />
        <Route element={<DocumentParser />} path={csvParserRoutes.index.relativePath} />
        <Route element={<Utilisation />} path={utilisationRoutes.index.relativePath} />
        <Route element={<RateCards />} path={rateCardRoutes.index.relativePath} />
        <Route
          element={
            <RouteAuthoriser path={accountsRoutes.accountsIndex.path}>
              <Accounts />
            </RouteAuthoriser>
          }
          path={accountsRoutes.accountsIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={accountSettingsRoutes.index.path}>
              <AccountLocation accountId={parentAccountId || accountUuid} backUrl={accountSettingsRoutes.locations.getUrl()} />
            </RouteAuthoriser>
          }
          path={accountSettingsRoutes.location.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={accountSettingsRoutes.index.path}>
              <AccountSettings />
            </RouteAuthoriser>
          }
          path={accountSettingsRoutes.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={opsDashboardRoutes.dashboardIndex.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <OperationsDashboardContainer />
            </RouteAuthoriser>
          }
          path={opsDashboardRoutes.dashboardIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={opsShipmentRoutes.shipmentIndex.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <OperationsShipmentsContainer />
            </RouteAuthoriser>
          }
          path={opsShipmentRoutes.shipmentIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={opsQuoteRoutes.quoteIndex.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <OperationsQuotesContainer />
            </RouteAuthoriser>
          }
          path={opsQuoteRoutes.quoteIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={opsSettingsRoutes.opsSettingsIndex.path}>
              <OperationsSettingsContainer />
            </RouteAuthoriser>
          }
          path={opsSettingsRoutes.opsSettingsIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={productSettingsRoutes.productSettingsIndex.path}>
              <ProductSettingsContainer />
            </RouteAuthoriser>
          }
          path={productSettingsRoutes.productSettingsIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={financeRoutes.index.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <Finance />
            </RouteAuthoriser>
          }
          path={financeRoutes.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={rulesEngineRoutes.index.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <RulesEngineContainer />
            </RouteAuthoriser>
          }
          path={rulesEngineRoutes.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser
              path={zencargoNetworksRoutes.myOrganisation.index.path}
              redirectUrl={dashboardRoutes.dashboard.getUrl()}
            >
              <ZencargoNetwork />
            </RouteAuthoriser>
          }
          path={zencargoNetworksRoutes.myOrganisation.index.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={consolidationRoutes.consolidationShipmentsDetailsPage.path}>
              <BookingDetails />
            </RouteAuthoriser>
          }
          path={consolidationRoutes.consolidationShipmentsDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={consolidationRoutes.consolidationAddShipmentsDetailsPage.path}>
              <BookingDetails />
            </RouteAuthoriser>
          }
          path={consolidationRoutes.consolidationAddShipmentsDetailsPage.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={consolidationRoutes.legacyConsolidationIndex.path}>
              <Consolidation />
            </RouteAuthoriser>
          }
          path={consolidationRoutes.legacyConsolidationIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={taskListRoutes.taskListIndex.path}>
              <TaskListContainer />
            </RouteAuthoriser>
          }
          path={taskListRoutes.taskListIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={workflowsRoutes.workflowsIndex.path}>
              <WorkflowsContainer />
            </RouteAuthoriser>
          }
          path={workflowsRoutes.workflowsIndex.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={dashboardRoutes.dashboard.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <DashboardContainer />
            </RouteAuthoriser>
          }
          path={dashboardRoutes.dashboard.relativePath}
        />
        <Route
          element={
            <RouteAuthoriser path={warehouseIntakeRoutes.index.path} redirectUrl={dashboardRoutes.dashboard.getUrl()}>
              <WarehouseIntake />
            </RouteAuthoriser>
          }
          path={warehouseIntakeRoutes.index.relativePath}
        />
      </Routes>
    </div>
  );
};

export default DashboardRoutes;
