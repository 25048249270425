const ACCOUNTS_RELATIVE_INDEX_PATH = 'accounts';

export const ACCOUNTS_ROUTE_PREFIX = `/dashboard/${ACCOUNTS_RELATIVE_INDEX_PATH}`;

interface AccountsRoutes {
  accountConnections: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountGeneral: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountMembers: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountWebhooks: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsAccountStatus: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsAuthentication: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsBilling: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsBusinessUnits: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsCompanyDetails: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  accountsCustomRoles: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsCustomRolesCreate: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsCustomRolesEdit: {
    getUrl: (accountId: string, roleId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsDetails: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsDivisions: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  accountsLocation: {
    getUrl: (accountId: string, locationId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsLocations: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsOpsCoordinators: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsProductAttributes: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
  accountsProductCategories: {
    getUrl: (accountId: string) => string;
    path: string;
    relativePath: string;
  };
}

const accountsRoutes: AccountsRoutes = {
  accountsIndex: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/*`,
    getUrl: () => ACCOUNTS_ROUTE_PREFIX,
    relativePath: `${ACCOUNTS_RELATIVE_INDEX_PATH}/*`
  },
  accountsAccountStatus: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/account-status`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/account-status`,
    relativePath: 'account-status'
  },
  accountsAuthentication: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/authentication`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/authentication`,
    relativePath: 'authentication'
  },
  accountsBilling: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/billing`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/billing`,
    relativePath: 'billing'
  },
  accountsBusinessUnits: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/business-units`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/business-unit`,
    relativePath: 'business-unit'
  },
  accountConnections: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/connections`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/connections`,
    relativePath: 'connections'
  },
  accountWebhooks: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/webhooks`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/webhooks`,
    relativePath: 'webhooks'
  },
  accountsCompanyDetails: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/company-details`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/company-details`,
    relativePath: 'company-details'
  },
  accountsCreate: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/new`,
    getUrl: () => `${ACCOUNTS_ROUTE_PREFIX}/new`,
    relativePath: 'new'
  },
  accountsDivisions: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/divisions`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/divisions`,
    relativePath: 'divisions'
  },
  accountGeneral: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/general`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/general`,
    relativePath: 'general'
  },
  accountsOpsCoordinators: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/ops-coordinators`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/ops-coordinators`,
    relativePath: 'ops-coordinators'
  },
  accountsCustomRoles: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/custom-roles/*`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/custom-roles`,
    relativePath: 'custom-roles/*'
  },
  accountsDetails: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id`,
    getUrl: (accountId: string): string => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}`,
    relativePath: ':id/*'
  },
  accountsCustomRolesCreate: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/custom-roles/create`,
    getUrl: (accountId: string): string => {
      return `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/custom-roles/create`;
    },
    relativePath: ':id/custom-roles/create'
  },
  accountsCustomRolesEdit: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/custom-roles/edit/:roleId`,
    getUrl: (accountId: string, roleId: string): string => {
      return `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/custom-roles/edit/${roleId}`;
    },
    relativePath: ':id/custom-roles/edit/:roleId'
  },
  accountsLocation: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:accountId/locations/:locationId`,
    getUrl: (accountId: string, locationId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/locations/${locationId}`,
    relativePath: ':accountId/locations/:locationId'
  },
  accountsLocations: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/locations/*`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/locations`,
    relativePath: 'locations/*'
  },
  accountMembers: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/members`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/members`,
    relativePath: 'members'
  },
  accountsProductAttributes: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/product-attributes`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/product-attributes`,
    relativePath: 'product-attributes'
  },
  accountsProductCategories: {
    path: `${ACCOUNTS_ROUTE_PREFIX}/:id/product-categories`,
    getUrl: (accountId: string) => `${ACCOUNTS_ROUTE_PREFIX}/${accountId}/product-categories`,
    relativePath: 'product-categories'
  }
};

export default accountsRoutes;
