import { get } from 'lodash';
import type { FC } from 'react';

import { useBusinessUnitOptions } from '@zen/Accounts/hooks/useBusinessUnitOptions';
import { Tooltip } from '@zen/DesignSystem';
import type { Option } from '@zen/DesignSystem/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import OptionsDropdown from '../../components/OptionsDropdown/OptionsDropdown';
import { useUpdateMemberBusinessUnitsMutation } from '../../graphql';

type ItemWithId = { id: string };

interface Props {
  accountId: string;
  buttonText: string;
  canAssignMemberBusinessUnits: boolean;
  isAdminRole: boolean;
  memberBusinessUnits: ItemWithId[];
  memberId: string;
}

const AssignBusinessUnitsButton: FC<Props> = ({
  accountId,
  buttonText,
  canAssignMemberBusinessUnits,
  isAdminRole,
  memberId,
  memberBusinessUnits
}) => {
  const [updateMemberBusinessUnits] = useUpdateMemberBusinessUnitsMutation();
  const { addError, addSuccess } = useNotification();
  const options: Option<string>[] = useBusinessUnitOptions(accountId);

  const handleApply = async (businessUnits: string[]): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateMemberBusinessUnits({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountMembers'],
          variables: { input: { memberId, businessUnits } }
        }),
      onError: (errors) => addError(get(errors, '[0].message', 'There was a problem updating the member.')),
      onSuccess: () => {
        addSuccess('Member was successfully updated.');
      }
    });
  };

  const selectedOptions: string[] = memberBusinessUnits?.map((businessUnit) => businessUnit.id) || [];

  if (canAssignMemberBusinessUnits && isAdminRole) {
    return (
      <Tooltip placement="bottom" tooltipContent="Admins have full access across all business units">
        <div className="px-3">All business units</div>
      </Tooltip>
    );
  }

  if (canAssignMemberBusinessUnits) {
    return (
      <OptionsDropdown
        allOptionsLabel="All business units"
        buttonText={buttonText}
        initialValues={selectedOptions}
        onApply={handleApply}
        options={options}
        renderPopoverInPortal={true}
      />
    );
  }

  if (!canAssignMemberBusinessUnits && selectedOptions.length === 0) {
    return <div className="px-3">All business units</div>;
  }

  if (!canAssignMemberBusinessUnits && selectedOptions.length > 0) {
    return <div className="px-3">{selectedOptions.length} business units</div>;
  }
};

export default AssignBusinessUnitsButton;
export type { Props as AssignBusinessUnitsButtonProps };
