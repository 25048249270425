import type { FC, ReactElement } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Table } from '@zen/DesignSystem';
import type { TableColumn } from '@zen/DesignSystem/types';
import type { AccountSettingsPermissions } from '@zen/graphql/types.generated';
import { usePagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { useUserGroupsOptions } from '../components/FormAccountUserGroupsSelect/useUserGroupsOptions';
import {
  type GetAccountMembersQueryResult,
  type GetAccountMembersQueryVariables,
  useGetAccountBusinessUnitsQuery,
  useGetAccountMembersQuery
} from '../graphql';
import type { AccountMember } from '../types';
import Filters from './AccountMembersFilters';
import getColumnsConfiguration from './accountMembersTableConfiguration';
import AddMemberModalButton from './AddMemberModalButton';

const initialFilters = {
  includeArchived: false,
  search: ''
};

interface Props {
  accountId: string;
  permissions?: AccountSettingsPermissions | null;
}

const AccountMembers: FC<Props> = ({ accountId, permissions }) => {
  const { filters, search, setSearch } = useUrlFilters(initialFilters);
  const { data } = useGetAccountBusinessUnitsQuery({ variables: { accountId, first: 20 } });
  const accountUserGroups = useUserGroupsOptions(accountId);

  const canCreateMembers: boolean = !!permissions?.canCreateMember?.value;
  const canEditMembers: boolean = !!permissions?.canEditMember?.value;

  const { loading, error, nodes, paginationInfo, refetch, totalCount } = usePagination<
    GetAccountMembersQueryResult,
    GetAccountMembersQueryVariables,
    AccountMember
  >(
    useGetAccountMembersQuery,
    'accountMembers',
    { accountId, accountMembersFiltersInput: { ...filters, searchQuery: search } },
    20,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const accountHasBusinessUnits: boolean = (data?.accountBusinessUnits?.nodes || []).length > 0;

  const handleSuccess = (): void => {
    setTimeout(() => {
      refetch();
    }, 1500);
  };

  const columns: TableColumn<AccountMember>[] = getColumnsConfiguration({
    accountHasBusinessUnits,
    accountId,
    accountUserGroups,
    canEdit: canEditMembers,
    onDelete: handleSuccess
  });

  const tableId: string = 'accountMembers';

  const renderAccountMembersTable = (accountMembers: AccountMember[]): ReactElement => (
    <Table<AccountMember>
      actions={
        canCreateMembers && (
          <AddMemberModalButton
            accountHasBusinessUnits={accountHasBusinessUnits}
            accountId={accountId}
            onSuccess={handleSuccess}
          />
        )
      }
      additionalActions={
        <div className="flex space-x-2">
          <Search
            collapsible={true}
            onClear={() => setSearch('')}
            onSubmit={setSearch}
            placeholder="Search account members..."
            size="compact"
            widthClassName="w-48"
          />
          <Filters />
        </div>
      }
      columns={columns}
      data={nodes || []}
      emptyText="No members"
      loading={loading}
      paginationInfo={paginationInfo}
      tableId={tableId}
      title="Members"
      totalCountConfig={{
        totalCount,
        entityName: 'members'
      }}
    />
  );

  return (
    <QueryHandler
      data={nodes}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonTableLoading columns={columns} rowCount={20} tableId={tableId} />}
      noResults={renderAccountMembersTable([])}
    >
      {(accountMembers: AccountMember[]) => {
        return renderAccountMembersTable(accountMembers);
      }}
    </QueryHandler>
  );
};

export default AccountMembers;
