import { get } from 'lodash';

import {
  useCreateAccountConnectionMutation,
  useCreateManagedAccountMutation,
  useInviteAccountConnectionMutation
} from '@zen/Accounts/AccountDetails/graphql';
import { AccountConnectionTypeEnum } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import type { CreateManagedAccountFormValues } from '../../CreateManagedAccountForm/types';
import { invitedCompanyId } from '../../DuplicatedAccountsForm/DuplicatedAccountsForm';
import type { InviteConnectionFormValues } from '../../InviteCompanyConnectionForm/types';
import type { MatchedCompanies } from '../types';

export const useAddConnections = (accountId: string, onSuccess: () => void) => {
  const { addError, addSuccess } = useNotification();

  const [inviteAccount] = useInviteAccountConnectionMutation({ refetchQueries: ['getAccountConnections'] });
  const [createConnection] = useCreateAccountConnectionMutation({ refetchQueries: ['getAccountConnections'] });
  const [createManagedAccount] = useCreateManagedAccountMutation({ refetchQueries: ['getAccountConnections'] });

  const createManagedAccountConnection = async ({
    businessHours,
    companyName,
    geolocation,
    name,
    shippingLocationAddress,
    usageContext
  }: CreateManagedAccountFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        createManagedAccount({
          variables: {
            input: {
              shippingLocation: {
                address: shippingLocationAddress,
                businessHours,
                geolocation: {
                  latitude: geolocation.latitude,
                  longitude: geolocation.longitude
                },
                name,
                usageContext
              },
              sourceAccountId: accountId,
              tradingName: companyName
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Something went wrong when adding the connection.'));
      },
      onSuccess: () => {
        onSuccess();
        addSuccess('Managed account added.');
      }
    });
  };

  const inviteAccountConnection = async ({ companyName, email }: InviteConnectionFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        inviteAccount({
          variables: {
            input: {
              inviteeCompanyTradingName: companyName,
              inviteeEmail: email,
              inviterAccountId: accountId
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Something went wrong when adding the connection.'));
      },
      onSuccess: () => {
        onSuccess();
        addSuccess('Connection invited.');
      }
    });
  };

  const handleAccountSelect = async (
    { companyId }: { companyId: string },
    matchedCompanies: Nullable<MatchedCompanies>
  ): Promise<IOkOrErrorResult> => {
    if (companyId === invitedCompanyId) {
      return inviteAccountConnection({ companyName: matchedCompanies?.companyName || '', email: matchedCompanies?.email || '' });
    }

    return performMutation({
      mutationFn: () =>
        createConnection({
          variables: {
            input: {
              connectionType: AccountConnectionTypeEnum.SUPPLIER,
              sourceAccountId: accountId,
              targetAccountId: companyId
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Something went wrong when adding the connection.'));
      },
      onSuccess: () => {
        onSuccess();
        addSuccess('Connection added.');
      }
    });
  };

  return { createManagedAccountConnection, handleAccountSelect, inviteAccountConnection };
};
