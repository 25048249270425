import type { FC } from 'react';
import { useState } from 'react';

import { Button } from '@zen/DesignSystem';
import type { OrganizationalRoleEnum } from '@zen/graphql/types.generated';

import AddLocationModal from '../AddLocationModal';

interface Props {
  accountId: string;
  organizationalRole?: OrganizationalRoleEnum;
}

const AddLocationButton: FC<Props> = ({ accountId, organizationalRole }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen: boolean): boolean => !isOpen);

  return (
    <>
      <AddLocationModal
        accountId={accountId}
        isOpen={isModalOpen}
        onClose={toggleModal}
        organizationalRole={organizationalRole}
      />
      <Button onClick={toggleModal} size="compact">
        Create location
      </Button>
    </>
  );
};

export default AddLocationButton;
