import type { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import AccountAvatar from '@zen/Components/AccountAvatar';
import { useTrackAccountSwitch } from '@zen/Components/VisitedAccounts';
import type { VisitedAccount } from '@zen/Components/VisitedAccounts/types';
import useAccount from '@zen/utils/hooks/useAccount';
import { extractFirstLevelPath } from '@zen/utils/routing';

interface Props {
  onAccountChange?: () => void;
  type: 'lastVisited' | 'mostFrequentlyVisited';
  visitedAccounts: VisitedAccount[];
}

const VisitedAccountsList: FC<Props> = ({ onAccountChange, type, visitedAccounts = [] }) => {
  const { accountUuid } = useAccount();
  const { switchToAccount } = useSwitchAccount();
  const trackAccountSwitch = useTrackAccountSwitch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const title: string = type === 'lastVisited' ? 'Recently selected' : 'Most frequently visited';

  const handleSwitchAccount = (account: VisitedAccount['account']): void => {
    onAccountChange?.();

    if (account.id === accountUuid) {
      return;
    }
    const newAccountCompanyName = account?.name;

    if (newAccountCompanyName) {
      trackAccountSwitch(newAccountCompanyName, type);
    }
    switchToAccount(account.id);
    navigate(extractFirstLevelPath(pathname));
  };

  const accounts = visitedAccounts.map((visitedAccount) => visitedAccount.account);

  return (
    <div className="py-2">
      <div className="text-xs text-grey-base mb-4">{title}</div>
      <ul>
        {accounts?.map(({ id, name }): ReactNode => {
          return (
            <li
              key={id}
              className="cursor-pointer group flex items-center mb-2"
              onClick={() => handleSwitchAccount({ id, name })}
            >
              <AccountAvatar accountName={name} className="mr-2.5 shrink-0" />
              <span className="text-sm group-hover:underline text-navy-base truncate">{name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VisitedAccountsList;
