import type { FC, PropsWithChildren } from 'react';

import { useAccountId } from '@zen/App/AccountIdContext';
import InactiveAccountLogin from '@zen/Components/InactiveAccountLogin';
import QueryHandler from '@zen/Components/QueryHandler';
import { SplashScreen } from '@zen/DesignSystem';
import useVisitedAccounts from '@zen/utils/hooks/useVisitedAccounts';

import useAuth from '../hooks/useAuth';
import { prepareUserProfile, saveUserData } from './helpers';
import useCurrentUser from './hooks/useCurrentUser';
import { AccountStatus, AccountType, type CurrentUserResponse, type UserContextData, type UserProfile } from './types';
import UserContext from './UserContext';

const UserDataProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { accountId } = useAccountId();
  const { updateVisitedAccounts } = useVisitedAccounts();
  const { data, loading, error, refetch: refetchUser } = useCurrentUser(accountId);
  const { logout } = useAuth();

  const extractAndSaveUserContext = (userData: CurrentUserResponse): UserContextData => {
    const userProfile: UserProfile = prepareUserProfile(userData);
    const divisions = userData.account?.businessUnits.filter((businessUnit) => businessUnit.type === AccountType.DIVISION) || [];

    // we need to save a few information about the user in localstorage
    // because it's needed for the GTM ( custom dimensions )
    saveUserData(userProfile);

    if (accountId) {
      updateVisitedAccounts(userProfile.uuid, {
        id: accountId,
        name: userData.account?.tradingName || ''
      });
    }

    return {
      accountStatus: userData.currentUserDetails?.account?.status || userData.account?.status,
      accountUuid: accountId,
      organizationalRole: userData.account?.organizationalRole || null,
      parentAccountId: userData.account?.parentAccountId || '',
      referencePrefix: userData.account?.referencePrefix,
      refetchUser,
      userProfile,
      divisions
    };
  };

  if (error) {
    logout();

    return null;
  }

  if (data?.currentUserDetails?.account?.status === AccountStatus.INACTIVE) {
    return <InactiveAccountLogin />;
  }

  return (
    <QueryHandler data={data} error={false} isLoading={loading} loadingComponent={<SplashScreen />} noResults={null}>
      {(userData) => {
        return <UserContext.Provider value={extractAndSaveUserContext(userData)}>{children}</UserContext.Provider>;
      }}
    </QueryHandler>
  );
};

export default UserDataProvider;
