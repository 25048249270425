import { type FC, useState } from 'react';

import EditCreateModal from '@zen/Components/EditCreateModal';
import type { CreateEditModalConfiguration } from '@zen/Components/EditCreateModal/types';
import { Button, Table } from '@zen/DesignSystem';
import { type SortInput } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import CreateProductAttribute from './CreateProductAttribute';
import EditProductAttribute from './EditProductAttribute';
import { useDeleteProductAttributeMutation } from './graphql';
import useProductAttributes from './hooks/useProductAttributes';
import { getColumns } from './productAttributes.tableConfiguration';
import { type CoreProductAttribute } from './types';

interface Props {
  accountId: string;
}

const ProductAttributes: FC<Props> = ({ accountId }) => {
  const [order, setOrder] = useState<SortInput | undefined>();
  const { loading, error, nodes, paginationInfo, totalCount } = useProductAttributes(accountId, order);
  const { addError, addSuccess } = useNotification();
  const [deleteProductAttribute] = useDeleteProductAttributeMutation();
  const [modalConfiguration, setModalConfiguration] = useState<CreateEditModalConfiguration<CoreProductAttribute>>();

  const handleAdd = (): void => {
    setModalConfiguration({
      type: 'create',
      title: 'Add new attribute type'
    });
  };

  const handleEdit = (attribute: CoreProductAttribute): void => {
    setModalConfiguration({
      type: 'edit',
      initialValues: attribute,
      title: 'Edit attribute type'
    });
  };

  const handleDelete = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        deleteProductAttribute({
          variables: {
            input: {
              id
            }
          },
          refetchQueries: ['productAttributes'],
          awaitRefetchQueries: true
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Product attribute has been deleted.');
      }
    });
  };
  const handleCloseModal = (): void => setModalConfiguration(undefined);

  return (
    <>
      <Table
        actions={
          <Button iconLeft="zicon-add" onClick={handleAdd} size="compact">
            Add new attribute type
          </Button>
        }
        columns={getColumns({ onEdit: handleEdit, onDelete: handleDelete })}
        data={nodes}
        error={!!error}
        loading={loading}
        onOrderChange={setOrder}
        order={order}
        paginationInfo={paginationInfo}
        tableId="product-attributes"
        title="Attribute types"
        totalCountConfig={{
          entityName: 'attribute type',
          isLoading: loading,
          totalCount
        }}
      />
      <EditCreateModal
        configuration={modalConfiguration}
        createForm={({ onClose }) => <CreateProductAttribute accountId={accountId} onClose={onClose} />}
        editForm={({ onClose, item }) => <EditProductAttribute item={item} onClose={onClose} />}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default ProductAttributes;
