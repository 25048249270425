import { type FC, type ReactNode } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components';
import type { FormInstance } from '@zen/Components/types';
import { Banner, Button, Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import type { AccountConnectionsSummary } from '../../types';
import { useUpgradeAccountConnectionMutation } from './graphql';
import type { UpgradeAccountFormType } from './types';
import { upgradeAccountFormValidation } from './upgradeAccountForm.validation';

interface Props {
  accountId: string;
  accountToUpgrade?: AccountConnectionsSummary;
  handleClose: () => void;
}

const UpgradeAccountModal: FC<Props> = ({ accountToUpgrade, handleClose, accountId }) => {
  const { addError, addSuccess } = useNotification();
  const [upgradeAccountConnection] = useUpgradeAccountConnectionMutation({
    refetchQueries: ['getAccountConnections']
  });

  const handleUpgradeConnection = (values: UpgradeAccountFormType): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        upgradeAccountConnection({
          variables: {
            input: {
              inviterAccountId: accountId,
              inviteeEmail: values.email,
              accountId: accountToUpgrade?.account.id || ''
            }
          }
        }),
      onError: () => addError('There was an error inviting the account. Please try again.')
    });
  };

  const initialValues: UpgradeAccountFormType = {
    email: '',
    companyName: accountToUpgrade?.account.tradingName || ''
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<UpgradeAccountFormType>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Invite connection">
      <Button data-testid="cancel-button" onClick={handleClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSuccess = () => {
    handleClose();
    addSuccess('Account was successfully invited.');
  };

  return (
    <Modal isOpen={!!accountToUpgrade} onClose={handleClose} title="Invite connection to join Zencargo">
      <div className="mb-4">
        <Banner
          message="Once invited, the account will move to a pending state, and you will no longer be able to manage locations on their behalf. Once they accept the invite, they will manage their own locations. They will still appear in your connections, and their locations will remain visible."
          type="warning"
        />
      </div>
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleUpgradeConnection}
        onSuccess={handleSuccess}
        validationSchema={upgradeAccountFormValidation}
      >
        {() => {
          return (
            <>
              <FormInput disabled={true} label="Company name" name="companyName" />
              <FormInput
                helperText="Ensure the recipient is able to set up the account on behalf of their company."
                label="Email"
                name="email"
              />
              <Banner message="Connections you invite will be listed as ‘Supplier’ in your connections. Once they accept and activate their account, they will see you as their ‘Customer’." />
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default UpgradeAccountModal;
