import {
  arrow,
  type ElementProps,
  flip,
  FloatingArrow,
  offset as offsetMiddleware,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react';
import cx from 'classnames';
import { type FC, type ReactNode, useEffect, useRef, useState } from 'react';

import Portal from '@zen/Components/Portal';

import { isRenderFunction } from './helper';
import type { ChildrenRenderFunction, PopoverPlacement, PopoverRenderFunction, PopoverTriggerEvent } from './types';

export type Props = {
  children: ReactNode | ChildrenRenderFunction;
  closeOnOutsideClick?: boolean;
  delayHide?: number;
  delayShow?: number;
  disabled?: boolean;
  interactive?: boolean;
  isVisible?: boolean;
  offset?: number;
  onVisibilityChange?: (visible: boolean) => void;
  placement?: PopoverPlacement;
  popover: ReactNode | PopoverRenderFunction;
  popoverClassNames?: string;
  renderInPortal?: boolean;
  showArrow?: boolean;
  trigger?: PopoverTriggerEvent;
  triggerClassName?: string;
  variant?: 'white' | 'dark';
};

const Popover: FC<Props> = (props) => {
  const {
    children,
    delayHide,
    delayShow,
    disabled = false,
    closeOnOutsideClick = true,
    offset = 10,
    placement = 'bottom-start',
    popover,
    onVisibilityChange,
    isVisible = false,
    popoverClassNames,
    renderInPortal,
    showArrow,
    trigger = 'click',
    triggerClassName,
    variant = 'white'
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const arrowRef = useRef(null);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const handleOpenChange = (open: boolean): void => {
    onVisibilityChange?.(open);
    setIsOpen(open);
  };

  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      arrow({
        element: arrowRef
      }),
      flip(),
      offsetMiddleware(offset)
    ],
    open: isOpen,
    onOpenChange: handleOpenChange,
    placement
  });

  const dismiss: ElementProps = useDismiss(context, {
    enabled: closeOnOutsideClick
  });
  const click: ElementProps = useClick(context, {
    enabled: trigger === 'click' && !disabled
  });
  const hover: ElementProps = useHover(context, {
    enabled: trigger === 'hover' && !disabled,
    delay: { close: delayHide, open: delayShow }
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, hover]);

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const renderChildren = (): ReactNode => {
    if (isRenderFunction(children)) {
      return children({ isPopoverVisible: isOpen });
    }

    return children;
  };

  const renderPopoverContent = (): ReactNode => {
    if (isRenderFunction(popover)) {
      return popover({ close: handleClose });
    }

    return popover;
  };

  const renderPopover = (): ReactNode => {
    const className: string = cx(
      {
        'bg-white': variant === 'white',
        'bg-navy-light text-navy-dark': variant === 'dark'
      },
      'z-50 rounded shadow-popover',
      popoverClassNames
    );
    const arrowClassName = cx({ 'fill-white': variant === 'white', 'fill-navy-light': variant === 'dark' });

    const popoverWrapper: ReactNode = (
      <div ref={refs.setFloating} className={className} role="tooltip" style={floatingStyles} {...getFloatingProps()}>
        <span>{renderPopoverContent()}</span>
        {showArrow && (
          <FloatingArrow
            ref={arrowRef}
            className={arrowClassName}
            context={context}
            data-testid="popover-arrow"
            height={6}
            width={12}
          />
        )}
      </div>
    );

    if (renderInPortal) {
      return <Portal>{popoverWrapper}</Portal>;
    }

    return popoverWrapper;
  };

  return (
    <>
      <div ref={refs.setReference} className={triggerClassName} {...getReferenceProps()}>
        {renderChildren()}
      </div>
      {isOpen && renderPopover()}
    </>
  );
};

export default Popover;
