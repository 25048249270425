import cx from 'classnames';

interface ClassConfig {
  className?: string;
  hasError: boolean;
  isInline: boolean;
}

export const getClassNames = ({ className, hasError, isInline }: ClassConfig) => ({
  button_next: 'inline-flex justify-center items-center w-10 h-10 text-grey-dark',
  button_previous: 'inline-flex justify-center items-center w-10 h-10 text-grey-dark',
  caption_label: 'font-bold text-lg px-1 text-grey-dark leading-8',
  day: 'group w-10 h-10 align-middle text-center text-grey-dark border-unset p-0',
  day_button:
    ' rounded w-10 h-10 transition-colors text-sm hover:bg-grey-dark/[.08]' +
    ' group-[.is-disabled]:opacity-[.48] group-[.is-disabled]:hover:!bg-white group-[.is-disabled]:cursor-not-allowed group-[.is-disabled]:js-disabled' +
    ' group-[.is-hidden]:hidden' +
    ' group-[.is-range-end]:rounded-none group-[.is-range-end]:rounded-r' +
    ' group-[.is-range-middle]:!bg-azure-lightest group-[.is-range-middle]:rounded-none group-[.is-range-middle]:!text-grey-dark group-[.is-range-middle]:cursor-pointer group-[.is-range-middle]:hover:bg-azure-lightest group-[.is-range-middle]:hover:!text-white hover:!rounded' +
    ' group-[.is-range-start]:rounded-none group-[.is-range-start]:rounded-l' +
    ' group-[.is-selected]:text-white group-[.is-selected]:bg-azure-base group-[.is-selected]:hover:!bg-azure-base group-[.is-selected]:cursor-default' +
    ' group-[.is-today]:border group-[.is-today]:border-solid group-[.is-today]:border-azure-base',
  disabled: 'is-disabled',
  dropdowns: 'flex space-x-1 items-baseline',
  hidden: 'is-hidden',
  months: cx(
    {
      'border-red-base': hasError,
      'border-grey-lighter': !hasError,
      rounded: !isInline
    },
    'border border-solid bg-white relative',
    className
  ),
  month_caption: cx(
    { 'rounded-tr rounded-tl': !isInline },
    'flex items-center justify-start items-center px-4 pt-4 pb-12 bg-grey-lightest text-xl font-bold'
  ),
  month_grid: 'border-collapse border-spacing-0 -mt-8 mx-3 mb-2.5',
  nav: 'absolute top-2 right-2',
  outside: 'enabled:opacity-50',
  range_end: 'is-range-end',
  range_middle: 'is-range-middle',
  range_start: 'is-range-start',
  selected: 'is-selected',
  today: 'is-today',
  weekday: 'w-10 h-10 uppercase align-middle text-center text-grey-base pb-7 font-normal'
});
