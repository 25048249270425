import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import { useTrackAccountSwitch } from '@zen/Components/VisitedAccounts';
import type { Option } from '@zen/DesignSystem/types';
import { OrganizationalRoleEnum } from '@zen/graphql/types.generated';
import { opsDashboardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { extractFirstLevelPath } from '@zen/utils/routing';
import type { Nullable } from '@zen/utils/typescript';

import { Form } from '../Form';
import FormAccountSelect from '../Form/FormAccountSelect';
import { validationSchema } from './accountSwitcherForm.validation';

interface Props {
  onAccountChange?: () => void;
  submitOnChange?: boolean;
}

const AccountSwitcherForm: FC<Props> = (props) => {
  const { onAccountChange, submitOnChange = false } = props;
  const { accountUuid } = useAccount();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { navigateBack } = useNavigationHistory();
  const { switchToAccount } = useSwitchAccount();
  const trackAccountSwitch = useTrackAccountSwitch();

  const backUrl: string = opsDashboardRoutes.dashboardIndex.getUrl();

  const handleSubmit = (values: { accountId: string }) => {
    const { accountId } = values;

    switchToAccount(accountId);

    return Promise.resolve({ ok: true, error: null });
  };

  const handleSuccess = () => {
    navigateBack(backUrl, (entry) => ({ ...entry, pathname: extractFirstLevelPath(entry.pathname) }));
  };

  const handleOnChange = (newAccountId: Nullable<string>, options: Option<string>[]) => {
    onAccountChange?.();

    if (!submitOnChange || newAccountId === accountUuid) {
      return;
    }

    const companyName: string = options.find(({ value }: Option<string>) => value === newAccountId)?.label || '';

    if (companyName) {
      trackAccountSwitch(companyName, 'accountSwitcherForm');
    }
    if (newAccountId) {
      switchToAccount(newAccountId);
    }
    navigate(extractFirstLevelPath(pathname));
  };

  const initialValues = {
    accountId: accountUuid
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={submitOnChange ? () => null : undefined}
      formName="AccountSwitcherForm"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      validationSchema={validationSchema}
    >
      {() => (
        <FormAccountSelect
          label="Account"
          name="accountId"
          onChange={handleOnChange}
          organizationalRole={[OrganizationalRoleEnum.CONSIGNMENT_PARTY]}
        />
      )}
    </Form>
  );
};

export default AccountSwitcherForm;
