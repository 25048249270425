import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import ProductCategoryForm from '../components/ProductCategoryForm';
import { useUpdateProductCategoryMutation } from '../graphql';
import type { CoreProductCategory } from '../types';

interface Props {
  item: CoreProductCategory;
  onClose: () => void;
}

const EditProductCategory: FC<Props> = ({ item, onClose }) => {
  const { addSuccess, addError } = useNotification();
  const [updateCategory] = useUpdateProductCategoryMutation();

  const handleSubmit = async (values: Pick<CoreProductCategory, 'name'>): Promise<IOkOrErrorResult> => {
    const { name } = values;

    return performFormMutation({
      mutationFn: () =>
        updateCategory({
          awaitRefetchQueries: true,
          refetchQueries: ['productCategories'],
          variables: {
            input: {
              id: item.id,
              name
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Product category updated successfully.');
    onClose();
  };

  return (
    <ProductCategoryForm
      buttonText="Save changes"
      initialValues={item}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default EditProductCategory;
