import type { FC } from 'react';

import type { PillProps } from '@zen/DesignSystem';
import { Headline, Pill } from '@zen/DesignSystem';
import type { AccountSettingsPermissions } from '@zen/graphql/types.generated';

import SSOKeyForm from './SSOKeyForm';
import type { AccountSSODetails, SSOKeyFormValues } from './types';

interface Props {
  accountId: string;
  accountName: string;
  permissions?: AccountSettingsPermissions | null;
  ssoDetails: AccountSSODetails;
}

const Authentication: FC<Props> = ({ accountName, accountId, permissions, ssoDetails }) => {
  const { enabled, connectionName, defaultRole, provisionMethod: initialProvisionMethod, domains } = ssoDetails;

  const initialValues: SSOKeyFormValues = {
    provisionMethod: initialProvisionMethod,
    connectionName,
    defaultRole,
    domains: domains.join(', ')
  };

  const pillProps: PillProps = enabled ? { children: 'Enabled', type: 'success' } : { children: 'Not enabled', type: 'pending' };

  const canEditSSO: boolean = !!permissions?.canEnableAuthentication?.value;

  return (
    <div>
      <Headline level={2}>Single sign on</Headline>
      <Pill className="my-4" {...pillProps} />
      <SSOKeyForm
        accountId={accountId}
        accountName={accountName}
        canEdit={canEditSSO}
        initialValues={initialValues}
        isSSOEnabled={enabled}
      />
    </div>
  );
};

export default Authentication;
export type { Props as AuthenticationTabProps };
