import type { FC } from 'react';

import type { Account } from '@zen/Accounts/graphql/types';
import { useSearchEntitledWithManagedAccount } from '@zen/Accounts/hooks/useSearchEntitledWithManagedAccount';
import FilterComponent from '@zen/Components/Filters';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { buildFilters } from './helpers';
import type { AccountLocationsFilterItem, AccountLocationsFilters } from './types';

interface Props {
  accountId: string;
}

const Filters: FC<Props> = (props: Props) => {
  const { accountId } = props;
  const { filters, setFilters } = useUrlFilters<AccountLocationsFilters>();
  const { entitledAccounts, loading, onSearch } = useSearchEntitledWithManagedAccount({ accountId });

  const accountFilters: Pick<Account, 'id' | 'tradingName'>[] = [];

  if (entitledAccounts) {
    if (entitledAccounts.account)
      accountFilters.push({
        id: entitledAccounts.account.id,
        tradingName: entitledAccounts.account.tradingName
      });

    entitledAccounts.businessUnits.forEach((businessUnit) => {
      accountFilters.push({
        id: businessUnit.id,
        tradingName: businessUnit.tradingName
      });
    });

    entitledAccounts.managed.forEach((managed) => {
      accountFilters.push({
        id: managed.id,
        tradingName: managed.tradingName
      });
    });

    entitledAccounts.unmanaged.forEach((unmanaged) => {
      accountFilters.push({
        id: unmanaged.id,
        tradingName: unmanaged.tradingName
      });
    });

    accountFilters.sort((account, nextAccount) => account.tradingName.localeCompare(nextAccount.tradingName));
  }

  const accountsFilterItem: AccountLocationsFilterItem = {
    title: 'Account',
    key: 'accountIds',
    props: {
      loading,
      onSearch,
      options: accountFilters.map((account) => ({
        label: account.tradingName,
        value: account.id
      }))
    },
    componentType: 'multi-select'
  };

  const filterItems: AccountLocationsFilterItem[] = buildFilters(accountsFilterItem);

  return <FilterComponent items={filterItems} onChange={setFilters} value={filters} />;
};

export default Filters;
