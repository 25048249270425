import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateAccountMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateAccountInput;
}>;

export type CreateAccountMutationResult = { __typename?: 'Mutation' } & {
  createAccount?: GraphQLTypes.Maybe<
    { __typename?: 'CreateAccountPayload' } & Pick<GraphQLTypes.CreateAccountPayload, 'accountId'> & {
        errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      }
  >;
};

export const CreateAccountDocument = /* #__PURE__ */ gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      accountId
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountMutationResult, CreateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateAccountMutationResult, CreateAccountMutationVariables>(CreateAccountDocument, options);
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
