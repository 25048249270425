export const ACCOUNT_SETTINGS_ROUTE_PREFIX = '/dashboard/account-settings';

interface AccountSettingsRoutes {
  authentication: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  businessUnits: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  connections: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  generalInformation: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  location: {
    getUrl: (locationId: string) => string;
    path: string;
    relativePath: string;
  };
  locations: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  members: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  productAttributes: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  productCategories: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  webhooks: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const accountSettingsRoutes: AccountSettingsRoutes = {
  index: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/*`,
    getUrl: () => ACCOUNT_SETTINGS_ROUTE_PREFIX,
    relativePath: 'account-settings/*'
  },
  authentication: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/authentication`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/authentication`,
    relativePath: 'authentication'
  },
  businessUnits: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/business-units`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/business-units`,
    relativePath: 'business-units'
  },
  connections: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/connections`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/connections`,
    relativePath: 'connections'
  },
  generalInformation: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/general`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/general`,
    relativePath: 'general'
  },
  location: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/locations/:locationId`,
    getUrl: (locationId: string) => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/locations/${locationId}`,
    relativePath: 'account-settings/locations/:locationId'
  },
  locations: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/locations/*`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/locations`,
    relativePath: 'locations/*'
  },
  members: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/members`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/members`,
    relativePath: 'members'
  },
  productAttributes: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/product-attributes`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/product-attributes`,
    relativePath: 'product-attributes'
  },
  productCategories: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/product-categories`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/product-categories`,
    relativePath: 'product-categories'
  },
  webhooks: {
    path: `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/webhooks`,
    getUrl: () => `${ACCOUNT_SETTINGS_ROUTE_PREFIX}/webhooks`,
    relativePath: 'webhooks'
  }
};

export default accountSettingsRoutes;
