import cx from 'classnames';
import { type ChangeEvent, type ReactNode, useState } from 'react';
import type { DropdownOption } from 'react-day-picker';

import { IconButton } from '@zen/DesignSystem/Button';
import Popover from '@zen/DesignSystem/Popover';
import { Headline } from '@zen/DesignSystem/Typography';

interface Props {
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  options?: DropdownOption[];
  value?: number | string | readonly string[];
}

const YearsDropdown = (props: Props) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

  const options: DropdownOption[] = props.options?.sort(({ value: a }, { value: b }) => b - a) || [];

  const handleClick = (value: number): void => {
    const event: unknown = {
      target: { value }
    };

    props.onChange?.(event as ChangeEvent<HTMLSelectElement>);

    setIsPopoverVisible(!isPopoverVisible);
  };

  const popover: ReactNode = (
    <div className="grid grid-cols-5 gap-4 p-4 max-h-[15rem] overflow-y-auto border border-solid rounded shadow-popover border-grey-lighter text-sm font-normal">
      {options.map((option: { label: string; value: number }, index: number) => {
        const classNames = cx('cursor-pointer hover:text-azure-base', { 'text-azure-base': option?.value === props.value });

        return (
          <div
            key={index}
            className={classNames}
            data-testid={`year-${option?.value}`}
            onClick={() => handleClick(option?.value)}
          >
            {option?.label}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="flex items-center space-x-2">
      <Headline level={2}>{props.value}</Headline>
      <Popover
        isVisible={isPopoverVisible}
        offset={10}
        onVisibilityChange={() => setIsPopoverVisible(!isPopoverVisible)}
        placement="bottom"
        popover={popover}
      >
        <IconButton disabled={props.disabled} icon="zicon-chevron-down" size="small" variant="ghost" />
      </Popover>
    </div>
  );
};

export default YearsDropdown;
