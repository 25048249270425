import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { Option } from '@zen/DesignSystem/types';

import { useGetEnterpriseConnectionsQuery } from '../graphql';
import type { EnterpriseConnection } from '../types';

const prepareEnterpriseConnectionOptions = (connectionNames: EnterpriseConnection): Option<string>[] =>
  connectionNames?.map((connectionName: string): Option<string> => {
    return {
      label: connectionName || '',
      value: connectionName || ''
    };
  }) || [];

interface Props {
  isDisabled: boolean;
}
const FormEnterpriseConnectionSelect: FC<Props> = ({ isDisabled }) => {
  const { data, loading } = useGetEnterpriseConnectionsQuery();

  const connectionNames = data?.enterpriseConnectionNames;

  const locationOptions: Option<string>[] = prepareEnterpriseConnectionOptions(connectionNames);

  return <FormSelect isDisabled={isDisabled} isLoading={loading} name="connectionName" options={locationOptions} />;
};

export default FormEnterpriseConnectionSelect;
