import type { DateRange } from '@zen/DesignSystem/types';

import type { FilterItemPropType } from '../types';
import FilterDatePicker from './components/FilterDatePicker';
import FilterDateRangePicker from './components/FilterDateRangePicker';
import FilterGroup from './components/FilterGroup';
import FilterMultiSelect from './components/FilterMultiSelect';
import FilterSelect from './components/FilterSelect';
import type { FilterComponentProps } from './types';

type Props = FilterComponentProps &
  FilterItemPropType<unknown> & {
    onChange: (value: unknown) => void;
    value: unknown;
  };

const FilterComponent = ({
  props,
  componentType,
  value,
  children,
  active,
  placement = 'bottom-start',
  onChange,
  onDelete,
  deselectFilter,
  disabled
}: Props) => {
  if (componentType === 'select') {
    return (
      <FilterSelect
        active={active}
        deselectFilter={deselectFilter}
        disabled={disabled}
        onChange={onChange}
        onDelete={onDelete}
        placement={placement}
        value={value}
        {...props}
      >
        {(formattedValue) => children(formattedValue)}
      </FilterSelect>
    );
  }

  if (componentType === 'multi-select') {
    return (
      <FilterMultiSelect
        active={active}
        deselectFilter={deselectFilter}
        disabled={disabled}
        onChange={onChange}
        onDelete={onDelete}
        placement={placement}
        value={value as unknown[]}
        {...props}
      >
        {(formattedValue) => children(formattedValue)}
      </FilterMultiSelect>
    );
  }

  if (componentType === 'group') {
    return (
      <FilterGroup
        active={active}
        deselectFilter={deselectFilter}
        disabled={disabled}
        onChange={onChange}
        onDelete={onDelete}
        placement={placement}
        value={value}
        {...props}
      >
        {(formattedValue) => children(formattedValue)}
      </FilterGroup>
    );
  }

  if (componentType === 'date-picker') {
    return (
      <FilterDatePicker
        active={active}
        deselectFilter={deselectFilter}
        disabled={disabled}
        onChange={onChange}
        onDelete={onDelete}
        placement={placement}
        value={value as string}
      >
        {(formattedValue) => children(formattedValue)}
      </FilterDatePicker>
    );
  }

  return (
    <FilterDateRangePicker
      active={active}
      deselectFilter={deselectFilter}
      disabled={disabled}
      onChange={onChange}
      onDelete={onDelete}
      placement={placement}
      value={value as DateRange}
      {...props}
    >
      {(formattedValue) => children(formattedValue)}
    </FilterDateRangePicker>
  );
};

export default FilterComponent;
