import { VisitedAccountsContext } from '@zen/Components/VisitedAccounts';
import type { VisitedAccounts } from '@zen/Components/VisitedAccounts/types';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

const useVisitedAccounts = (): VisitedAccounts => {
  return useContextOrThrowError<VisitedAccounts>(
    VisitedAccountsContext,
    'useVisitedAccounts must be used inside VisitedAccountsProvider'
  );
};

export default useVisitedAccounts;
