import cx from 'classnames';
import type { FC, MouseEvent, PropsWithChildren, ReactNode } from 'react';

import type { IconType } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';
import type { StyledButtonProps } from './StyledButton';
import StyledButton from './StyledButton';

interface Props extends StyledButtonProps {
  iconLeft?: IconType;
  iconRight?: IconType;
  size?: 'default' | 'compact';
}

export const displayName: string = 'Design System/Button';

const Button: FC<PropsWithChildren<Props>> = (props) => {
  const { trackEvent } = useTracking();

  const { className, children, disabled = false, iconLeft, iconRight, onClick, size = 'default', tooltip, type, ...rest } = props;

  const buttonClassNames: string = cx(
    {
      'h-10 px-4': size === 'default',
      'h-8 px-3': size === 'compact'
    },
    'space-x-2 rounded',
    className
  );

  const renderIcon = (icon: IconType): ReactNode => {
    return typeof icon === 'string' ? <Icon className="text-base" icon={icon} /> : icon;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.(event);
  };

  return (
    <StyledButton
      className={buttonClassNames}
      data-component="button"
      disabled={disabled}
      onClick={handleClick}
      tooltip={tooltip}
      type={type}
      {...createTrackingParentAttribute('button')}
      {...rest}
    >
      {iconLeft && renderIcon(iconLeft)}
      <span className="flex items-center justify-center whitespace-nowrap" {...createTrackingLabelAttribute('button')}>
        {children}
      </span>
      {iconRight && renderIcon(iconRight)}
    </StyledButton>
  );
};

export type { Props as ButtonProps };

export default Button;
