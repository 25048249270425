import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInput, FormSelect } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CoreProductAttribute } from '../../types';
import { CoreProductAttributeType, productAttributeTypeLabels } from '../../types';
import { validationSchema } from './productAttributeForm.validation';

interface Props {
  buttonText: string;
  initialValues?: Partial<CoreProductAttribute>;
  onCancel: () => void;
  onSubmit: (values: Pick<CoreProductAttribute, 'name' | 'type'>) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const ProductAttributeForm: FC<Props> = (props) => {
  const { buttonText, initialValues, onCancel, onSubmit, onSuccess } = props;

  const typeOptions = Object.values(CoreProductAttributeType).map((type) => ({
    label: productAttributeTypeLabels[type],
    value: type
  }));

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text={buttonText}>
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="ProductAttributeForm"
      initialValues={initialValues || { name: '', type: null }}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <FormInput label="Attribute type name" name="name" placeholder="Colour" />
          <FormSelect
            helperText="You can’t change this attribute format once it is created."
            isDisabled={!!initialValues?.type}
            label="Format"
            name="type"
            options={typeOptions}
            renderMenuInPortal={true}
          />
        </>
      )}
    </Form>
  );
};

export default ProductAttributeForm;
