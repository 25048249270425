import { get } from 'lodash';
import type { FC } from 'react';

import {
  useCreateAccountLocationMutation,
  useUpdateAccountLocationDetailsMutation,
  useUpdateLocationAddressMutation,
  useUpdateLocationBusinessHoursMutation
} from '@zen/Accounts/AccountDetails/graphql';
import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import BusinessHoursFieldsTable from '@zen/Accounts/components/forms/BusinessHoursFieldsTable';
import BusinessHoursRadioGroup from '@zen/Accounts/components/forms/BusinessHoursRadioGroup';
import ShippingLocationFields from '@zen/Accounts/components/forms/ShippingLocationFields';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { getDefaultWorkDays } from '../../AccountForms/helpers';
import type { AccountLocationDetails } from '../../AccountForms/types';
import type { UpdateAccountProps } from '../../components/forms/types';
import { prepareCreateLocationValues, prepareLocationAddressValues, prepareLocationDetailsValues } from './helpers';
import { updateShippingLocationValidation } from './UpdateShippingLocation.validation';

type Props = UpdateAccountProps<AccountLocationDetails> & { accountName: string };

const UpdateShippingLocation: FC<Props> = ({ onCancel, onSuccess, initialValues, accountName }) => {
  const { addError } = useNotification();
  const [createLocation] = useCreateAccountLocationMutation({ refetchQueries: ['getAccountDetails'] });
  const [updateLocationAddress] = useUpdateLocationAddressMutation({ refetchQueries: ['getAccountDetails'] });
  const [updateLocationBusinessHours] = useUpdateLocationBusinessHoursMutation({ refetchQueries: ['getAccountDetails'] });
  const [updateLocationDetails] = useUpdateAccountLocationDetailsMutation({ refetchQueries: ['getAccountDetails'] });

  const { accountId } = initialValues;

  const handleUpdateAccount = async (values: AccountLocationDetails): Promise<IOkOrErrorResult> => {
    const { businessHours, locationId, shippingLocation } = values;

    const errors: string[] = [];

    if (!locationId) {
      await performFormMutation({
        mutationFn: () =>
          createLocation({
            variables: {
              input: prepareCreateLocationValues(values, accountId)
            }
          }),
        onError: (err) => {
          errors.push(get(err, '[0].message', 'There was a problem updating the address.'));
        }
      });
    } else {
      await performFormMutation({
        mutationFn: () =>
          updateLocationDetails({
            variables: {
              input: prepareLocationDetailsValues(values, accountId)
            }
          }),
        onError: (err) => {
          errors.push(get(err, '[0].message', 'There was a problem updating the location details.'));
        }
      });

      await performFormMutation({
        mutationFn: () =>
          updateLocationAddress({
            variables: {
              input: prepareLocationAddressValues(values)
            }
          }),
        onError: (err) => {
          errors.push(get(err, '[0].message', 'There was a problem updating the address.'));
        }
      });

      await performFormMutation({
        mutationFn: () =>
          updateLocationBusinessHours({
            variables: {
              input: {
                locationId,
                businessHours: shippingLocation.businessHoursOption === 'standard' ? getDefaultWorkDays() : businessHours
              }
            }
          }),
        onError: (err) => {
          errors.push(get(err, '[0].message', 'There was a problem updating the business hours.'));
        }
      });
    }

    if (errors.length > 0) {
      errors.forEach((error) => addError(error));

      return Promise.reject({ ok: null, error: true });
    }

    return Promise.resolve({ ok: {}, error: null });
  };

  return (
    <AccountFormWrapper<AccountLocationDetails>
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleUpdateAccount}
      onSuccess={onSuccess}
      validationSchema={updateShippingLocationValidation}
    >
      {({ values }) => {
        const hasCustomBusinessHours: boolean = values.shippingLocation.businessHoursOption === 'custom';

        return (
          <>
            <ShippingLocationFields
              address={values.shippingLocationAddress}
              fieldPrefix="shippingLocation"
              geolocation={values.shippingLocation.geolocation}
            />
            <BusinessHoursRadioGroup fieldPrefix="shippingLocation" />
            {hasCustomBusinessHours && <BusinessHoursFieldsTable />}
          </>
        );
      }}
    </AccountFormWrapper>
  );
};

export default UpdateShippingLocation;
