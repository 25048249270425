import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import FormBusinessUnitsSelect from '@zen/Accounts/AccountDetails/components/FormAccountBusinessUnitsSelect/FormAccountBusinessUnitsSelect';
import { Form, FormArray, FormButtons, FormInput, FormMultiSelect } from '@zen/Components/Form';
import type { FormArrayHelpers, FormInstance } from '@zen/Components/Form/types';
import { Button, Headline, IconButton } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { Webhook, WebhookHeader } from '../types';
import { emptyHeader, topicOptions } from './helpers';
import { createValidationSchema } from './webhookForm.validation';

interface Props {
  accountId: string;
  buttonText: string;
  initialValues?: Webhook;
  isBusinessUnitFieldVisible: boolean;
  onCancel: () => void;
  onSubmit: (values: Webhook) => Promise<IOkOrErrorResult>;
  onSuccess?: (data: { signToken: string }) => void;
}

const WebhookForm: FC<Props> = (props) => {
  const { accountId, buttonText, initialValues, isBusinessUnitFieldVisible, onCancel, onSubmit, onSuccess } = props;

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text={buttonText}>
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      formName="WebhookForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={createValidationSchema(isBusinessUnitFieldVisible)}
    >
      {({ values }: FormInstance<Webhook>) => {
        return (
          <>
            {isBusinessUnitFieldVisible && (
              <FormBusinessUnitsSelect
                accountId={accountId}
                hideLabel={false}
                isMultiple={false}
                label="Business unit"
                name="businessUnit"
                noMargin={false}
              />
            )}
            <FormInput name="name" />
            <FormInput
              helperText="Zencargo will do a POST request and send a JSON payload to this URL with details about topics when they trigger."
              name="callbackUrl"
            />
            <FormMultiSelect name="topics" options={topicOptions} placeholder="" renderMenuInPortal={true} />
            <FormInput
              helperText="Zencargo will send an email to this address in case the Callback URL is unreachable."
              name="contactEmail"
            />
            <Headline level={4}>Webhook headers</Headline>
            <div>
              <FormArray
                addButtonText="Add header"
                empty={emptyHeader}
                isCompact={true}
                noMargin={true}
                path="headers"
                values={values.headers as WebhookHeader[]}
              >
                {({ getFieldName, index, remove }: FormArrayHelpers<WebhookHeader>) => {
                  const lineItemClassName: string = cx({ 'mt-4': !index }, 'flex flex-col');

                  return (
                    <div key={index} className={lineItemClassName}>
                      <div className="flex items-start justify-center">
                        <FormInput className="flex-1 mr-4" label="Header name" name={getFieldName('name')} />
                        <FormInput className="flex-1 mr-4" label="Header value" name={getFieldName('value')} />
                        <IconButton
                          className="mt-6 leading-6"
                          icon="zicon-trash"
                          onClick={remove}
                          title="Remove"
                          variant="ghost"
                        />
                      </div>
                    </div>
                  );
                }}
              </FormArray>
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default WebhookForm;
