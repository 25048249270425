const Query = {
  fields: {
    accounts: {
      read(existing: unknown) {
        return existing;
      },
      keyArgs: ['textContains', 'order', 'after', 'before', 'first', 'last']
    },
    accountMembers: {
      read(existing: unknown) {
        return existing;
      },
      keyArgs: ['accountId', 'accountMembersFiltersInput', 'after', 'before', 'first', 'last']
    },
    accountConnections: {
      read(existing: unknown) {
        return existing;
      },
      keyArgs: ['accountId', 'accountConnectionsFiltersInput', 'after', 'before', 'first', 'last']
    },
    bookings: {
      read(existing: unknown) {
        return existing;
      },
      keyArgs: [
        'active',
        'after',
        'archived',
        'before',
        'consignees',
        'consignors',
        'consolidatedShipmentId',
        'customerUuid',
        'customers',
        'destinationCountries',
        'destinations',
        'forwarders',
        'incoterms',
        'issues',
        'onlyBookingRequests',
        'onlyManufacturerRequested',
        'onlyQuotes',
        'onlyReadyForApproval',
        'onlyRejected',
        'onlyShipments',
        'operationsManagers',
        'order',
        'originCountries',
        'origins',
        'showExceptions',
        'showInTransit',
        'showPinned',
        'showSubscribed',
        'stages',
        'textContains',
        'transportModes',
        'vesselNames',
        'withPendingActionItems',
        'withSteps',
        'withinTimeRange',
        'zencargoReferences'
      ]
    }
  },
  coreBookings: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['id, reference', 'modeOfTransport', 'incoterm']
  },
  consolidations: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['after', 'before', 'customerUuid', 'first', 'consolidatedShipmentId']
  },
  globalUsers: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['globalUsersFiltersInput', 'after', 'before', 'first', 'last', 'order']
  },
  network: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['networkId', 'after', 'before', 'first', 'last'],
    fields: {
      myOrganisation: {
        fields: {
          contacts: {
            read(existing: unknown) {
              return existing;
            },
            keyArgs: ['after', 'before', 'first', 'last']
          }
        }
      }
    }
  },
  purchaseOrders: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: [
      'customerUuid',
      'orderReferenceNumber',
      'id',
      'ids',
      'manufacturerIds',
      'textContains',
      'cargoReadyDate',
      'cargoReadyDateBetween',
      'lastUpdatedBetween',
      'portOfLoadUnlocode',
      'portOfDestinationUnlocode',
      'incoterms',
      'filterBy',
      'order',
      'bookingState',
      'issues',
      'zencargoReferences',
      'availableForBooking',
      'availableForCargo',
      'after',
      'before'
    ]
  },
  purchaseOrdersLots: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: [
      'purchaseOrderIds',
      'ids',
      'availableForBooking',
      'availableForCargo',
      'onlyNotBooked',
      'paginated',
      'after',
      'before',
      'first',
      'last'
    ]
  },
  purchaseOrdersOriginAgentAssignmentRules: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['id', 'order', 'paginated', 'after', 'before', 'first', 'last']
  },
  products: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['customerId', 'nameContains', 'skuContains', 'textContains', 'archived', 'ids', 'after', 'before', 'first', 'last']
  },
  roadTrackedShipments: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['active', 'customerId', 'status']
  },
  shipmentsTableCargoGroups: {
    read(existing: unknown) {
      return existing;
    },
    keyArgs: ['accountIds, groupBy', 'filterBy', 'sortBy', 'first', 'last', 'after', 'before', 'searchTerm']
  }
};

export default Query;
