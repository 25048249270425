import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { IconButton } from '@zen/DesignSystem';
import type { TableColumn } from '@zen/DesignSystem/types';

import type { CoreProductCategory } from './types';

interface Params {
  onDelete: (id: string) => void;
  onEdit: (category: CoreProductCategory) => void;
}

export const getColumns = ({ onEdit, onDelete }: Params): TableColumn<CoreProductCategory>[] => [
  {
    key: 'name',
    title: 'Category name'
  },
  {
    key: 'actions',
    sortable: false,
    fixed: 'right',
    title: '',
    render: (_, category: CoreProductCategory) => {
      return (
        <div className="flex gap-2">
          <IconButton icon="zicon-edit" onClick={() => onEdit(category)} size="medium" title="Edit" variant="ghost" />

          <WithConfirmationModal
            confirmLabel="Delete"
            header="Delete category"
            message="Are you sure you want to delete this category? It won't be removed from any current products that have it, but you won't be able to add it to any new products."
            onConfirm={() => onDelete(category.id)}
          >
            <IconButton icon="zicon-trash" size="medium" title="Delete" variant="ghost" />
          </WithConfirmationModal>
        </div>
      );
    }
  }
];
