import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { IconButton } from '@zen/DesignSystem';
import type { TableColumn } from '@zen/DesignSystem/types';

import { type CoreProductAttribute, productAttributeTypeLabels } from './types';

interface Params {
  onDelete: (id: string) => void;
  onEdit: (attribute: CoreProductAttribute) => void;
}

export const getColumns = ({ onEdit, onDelete }: Params): TableColumn<CoreProductAttribute>[] => [
  {
    key: 'name',
    title: 'Attribute type name'
  },
  {
    key: 'type',
    sortable: false,
    title: 'Format',
    render: (_, attribute: CoreProductAttribute) => {
      return <span>{productAttributeTypeLabels[attribute.type]}</span>;
    }
  },
  {
    key: 'actions',
    sortable: false,
    title: '',
    fixed: 'right',
    render: (_, attribute: CoreProductAttribute) => {
      return (
        <div className="flex gap-2">
          <IconButton icon="zicon-edit" onClick={() => onEdit(attribute)} size="medium" title="Edit" variant="ghost" />

          <WithConfirmationModal
            confirmLabel="Delete"
            header="Delete attribute"
            message="Are you sure you want to delete this attribute? It won't be removed from any current products that have it, but you won't be able to add it to any new products."
            onConfirm={() => onDelete(attribute.id)}
          >
            <IconButton icon="zicon-trash" size="medium" title="Delete" variant="ghost" />
          </WithConfirmationModal>
        </div>
      );
    }
  }
];
