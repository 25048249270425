import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductCategoriesQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.CoreProductCategoryOrderInput> | GraphQLTypes.CoreProductCategoryOrderInput>;
}>;

export type ProductCategoriesQueryResult = { __typename?: 'Query' } & {
  coreProductCategories?: GraphQLTypes.Maybe<
    { __typename?: 'CoreProductCategoryConnection' } & Pick<GraphQLTypes.CoreProductCategoryConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<{ __typename?: 'CoreProductCategory' } & Pick<GraphQLTypes.CoreProductCategory, 'id' | 'name'>>
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const ProductCategoriesDocument = /* #__PURE__ */ gql`
  query productCategories(
    $accountId: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [CoreProductCategoryOrderInput!]
  ) {
    coreProductCategories(accountId: $accountId, first: $first, last: $last, after: $after, before: $before, order: $order) {
      nodes {
        id
        name
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useProductCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
}
export function useProductCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
}
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
