import type { RadioGroupOption } from '@zen/Components/RadioGroup/types';
import { AccountConnectionTypeEnum, OrganizationalRoleEnum } from '@zen/graphql/types.generated';

import type { ConnectionFormType } from './types';

export const constructConnectionFormOptions = (
  canInviteConnections: boolean,
  canCreateManagedAccounts: boolean,
  canLinkAgentToCustomer: boolean,
  canLinkCustomerToSupplier: boolean
): RadioGroupOption<ConnectionFormType>[] => {
  const connectionFormOptions: RadioGroupOption<ConnectionFormType>[] = [];

  if (canInviteConnections)
    connectionFormOptions.push({
      label: 'Invite connection to join Zencargo',
      value: 'invite'
    });

  if (canCreateManagedAccounts)
    connectionFormOptions.push({
      label: 'Create and manage on behalf of connection',
      value: 'managed'
    });

  if (canLinkAgentToCustomer)
    connectionFormOptions.push({
      label: 'Link to an existing customer',
      value: 'linkedAgentCustomer'
    });

  if (canLinkCustomerToSupplier)
    connectionFormOptions.push({
      label: 'Link to an existing connection',
      value: 'linkedCustomerSupplier'
    });

  return connectionFormOptions;
};

export const getConnectionType = (organizationalRole: OrganizationalRoleEnum): AccountConnectionTypeEnum => {
  if (organizationalRole === OrganizationalRoleEnum.AGENT) return AccountConnectionTypeEnum.AGENT;
  if (organizationalRole === OrganizationalRoleEnum.CONSIGNMENT_PARTY) return AccountConnectionTypeEnum.SUPPLIER;

  return AccountConnectionTypeEnum.CUSTOMER;
};
