import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetMembersQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetMembersQueryResult = { __typename?: 'Query' } & {
  currentUserDetails?: GraphQLTypes.Maybe<
    { __typename?: 'AccountMember' } & {
      account: { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'organizationalRole'>;
    }
  >;
};

export const GetMembersDocument = /* #__PURE__ */ gql`
  query getMembers {
    currentUserDetails {
      account {
        id
        organizationalRole
      }
    }
  }
`;

/**
 * __useGetMembersQuery__
 *
 * To run a query within a React component, call `useGetMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMembersQueryResult, GetMembersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetMembersQueryResult, GetMembersQueryVariables>(GetMembersDocument, options);
}
export function useGetMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMembersQueryResult, GetMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetMembersQueryResult, GetMembersQueryVariables>(GetMembersDocument, options);
}
export type GetMembersQueryHookResult = ReturnType<typeof useGetMembersQuery>;
export type GetMembersLazyQueryHookResult = ReturnType<typeof useGetMembersLazyQuery>;
