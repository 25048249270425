import type { FC } from 'react';

import RadioGroup from '@zen/Components/RadioGroup';
import type { RadioGroupOption } from '@zen/Components/RadioGroup/types';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';

interface FormRadioGroupField {
  name: string;
  value: string;
}

interface Props extends FormFieldProps {
  disabled?: boolean;
  onChange?: (value: string) => void;
  options: RadioGroupOption[];
  radioAlignment?: 'row' | 'column';
  radioType?: 'default' | 'tile';
}

const FormRadioGroup: FC<Props> = ({ onChange, options, ...rest }) => {
  return (
    <FormField {...rest}>
      {(field: FormRadioGroupField, form: FormInstance<unknown>) => {
        const handleChange = (value: string): void => {
          form.setFieldTouched(field.name, true);
          form.setFieldValue(field.name, value);

          onChange?.(value);
        };

        return <RadioGroup {...field} onChange={handleChange} options={options} />;
      }}
    </FormField>
  );
};

export default FormRadioGroup;
