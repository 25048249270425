import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import ProductCategoryForm from '../components/ProductCategoryForm';
import { useCreateProductCategoryMutation } from '../graphql';
import type { CoreProductCategory } from '../types';

interface Props {
  accountId: string;
  onClose: () => void;
}

const CreateProductCategory: FC<Props> = ({ accountId, onClose }) => {
  const { addSuccess, addError } = useNotification();
  const [createCategory] = useCreateProductCategoryMutation();

  const handleSubmit = async (values: Pick<CoreProductCategory, 'name'>): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        createCategory({
          refetchQueries: ['productCategories'],
          variables: {
            input: {
              accountId,
              name: values.name
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (): void => {
    addSuccess('Product category added successfully.');
    onClose();
  };

  return (
    <ProductCategoryForm
      buttonText="Add"
      initialValues={{ name: '' }}
      onCancel={onClose}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default CreateProductCategory;
