import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateAccountHeadquartersMutation } from '../../graphql';

interface Props {
  accountId: string;
  accountName: string;
  isOpen: boolean;
  locationId: string;
  onClose: () => void;
}

const UpdateAccountHeadquartersModal: FC<Props> = ({ accountId, accountName, isOpen, locationId, onClose }) => {
  const { addError, addSuccess } = useNotification();
  const [updateAccountHeadquarters, { loading }] = useUpdateAccountHeadquartersMutation();

  const handleConfirm = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateAccountHeadquarters({
          awaitRefetchQueries: false,
          refetchQueries: ['getAccountLocations'],
          variables: {
            input: {
              accountId,
              newHeadquartersLocationId: locationId
            }
          }
        }),
      onError: () => {
        onClose();
        addError('Something went wrong when trying to update the headquarters.', {
          label: 'Try again',
          onClick: handleConfirm
        });
      },
      onSuccess: () => {
        onClose();
        addSuccess('Headquarters updated.');
      }
    });
  };

  return (
    <Dialog
      confirmLabel="Make HQ"
      header={`Make this location the HQ for ${accountName}?`}
      isLoading={loading}
      isOpen={isOpen}
      message="The HQ is your company's legal address, but it can also serve as a shipping location. We'll make it simple to assign your HQ as the consignor or consignee on your shipments."
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default UpdateAccountHeadquartersModal;
export type { Props as UpdateAccountHeadquartersModalProps };
