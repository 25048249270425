import type { ApolloCache } from '@apollo/client';
import { get } from 'lodash';
import type { FC } from 'react';
import { useState } from 'react';

import OptionList from '@zen/Components/Filters/FilterComponents/components/OptionList';
import { Button, Popover } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import type { UserGroupOption } from '../../components/FormAccountUserGroupsSelect/useUserGroupsOptions';
import type { UpdateAccountMemberUserGroupMutationResult } from '../../graphql/updateAccountMemberUserGroup.generated';
import { useUpdateAccountMemberUserGroupMutation } from '../../graphql/updateAccountMemberUserGroup.generated';
import type { AccountUserGroup } from '../../types';

interface Props {
  accountId: string;
  accountUserGroups: UserGroupOption[];
  assignedRole: Pick<AccountUserGroup, 'name' | 'userGroupId'>;
  canAssignMemberRole: boolean;
  memberId: string;
}

const AssignMemberUserGroupButton: FC<Props> = ({
  accountId,
  accountUserGroups,
  assignedRole: role,
  canAssignMemberRole,
  memberId
}) => {
  const [updateMemberUserGroup] = useUpdateAccountMemberUserGroupMutation();
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const { addError, addSuccess } = useNotification();

  // This will allow the page to render so Dan can demo - Will be removed.
  const assignedRole = role || { name: 'Member', userGroupId: accountUserGroups[0].value };

  const handleApply = async (userGroupId: string): Promise<void> => {
    setIsPopoverVisible(false);

    if (userGroupId === assignedRole.userGroupId) return;

    await performMutation({
      mutationFn: () =>
        updateMemberUserGroup({
          update: (cache: ApolloCache<UpdateAccountMemberUserGroupMutationResult>) => {
            const selectedUserGroup = accountUserGroups.find(({ value }) => value === userGroupId)?.accountUserGroup;

            return cache.modify({
              id: cache.identify({ __typename: 'AccountMember', id: memberId }),
              fields: {
                assignedRoles() {
                  return [
                    {
                      __typename: 'AccountUserGroup',
                      name: selectedUserGroup?.name,
                      userGroupId: selectedUserGroup?.userGroupId
                    }
                  ];
                }
              }
            });
          },
          variables: {
            input: {
              accountId,
              memberId,
              userGroupId
            }
          }
        }),
      onError: (errors) => addError(get(errors, '[0].message', 'There was a problem updating the member user role.')),
      onSuccess: () => {
        addSuccess('Member user role was successfully updated.');
      }
    });
  };

  const items = accountUserGroups.map((userGroup) => ({
    label: userGroup.renderOptionLabel(),
    isSelected: userGroup.value === assignedRole.userGroupId,
    onClick: () => handleApply(userGroup.value)
  }));

  const popover = <OptionList list={[{ items }]} />;

  return (
    <>
      {canAssignMemberRole && (
        <Popover
          closeOnOutsideClick={true}
          isVisible={isPopoverVisible}
          onVisibilityChange={() => setIsPopoverVisible(!isPopoverVisible)}
          popover={popover}
          popoverClassNames="border border-solid border-grey-lighter"
          renderInPortal={true}
        >
          <Button disabled={!canAssignMemberRole} iconRight="zicon-chevron-down-small" size="compact" variant="ghost">
            {assignedRole.name}
          </Button>
        </Popover>
      )}
      {!canAssignMemberRole && assignedRole.name}
    </>
  );
};

export default AssignMemberUserGroupButton;
export type { Props as AssignMemberUserGroupButtonProps };
