import { type FC, useState } from 'react';

import EditCreateModal from '@zen/Components/EditCreateModal';
import type { CreateEditModalConfiguration } from '@zen/Components/EditCreateModal/types';
import { Button, Table } from '@zen/DesignSystem';
import type { SortInput } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import CreateProductCategory from './CreateProductCategory';
import EditProductCategory from './EditProductCategory';
import { useDeleteProductCategoryMutation } from './graphql';
import useProductCategories from './hooks/useProductCategories';
import { getColumns } from './productCategories.tableConfiguration';
import type { CoreProductCategory } from './types';

interface Props {
  accountId: string;
}

const ProductCategories: FC<Props> = ({ accountId }) => {
  const [order, setOrder] = useState<SortInput | undefined>();
  const { loading, error, nodes, paginationInfo, totalCount } = useProductCategories(accountId, order);
  const { addError, addSuccess } = useNotification();
  const [deleteProductCategory] = useDeleteProductCategoryMutation();
  const [modalConfiguration, setModalConfiguration] = useState<CreateEditModalConfiguration<CoreProductCategory>>();

  const handleAdd = (): void => {
    setModalConfiguration({
      type: 'create',
      title: 'Add new category'
    });
  };

  const handleEdit = (category: CoreProductCategory): void => {
    setModalConfiguration({
      type: 'edit',
      initialValues: category,
      title: 'Edit product category'
    });
  };

  const handleDelete = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        deleteProductCategory({
          variables: {
            input: {
              id
            }
          },
          refetchQueries: ['productCategories'],
          awaitRefetchQueries: true
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Product category has been deleted.');
      }
    });
  };
  const handleCloseModal = (): void => setModalConfiguration(undefined);

  return (
    <>
      <Table
        actions={
          <Button iconLeft="zicon-add" onClick={handleAdd} size="compact">
            Add new category
          </Button>
        }
        columns={getColumns({ onEdit: handleEdit, onDelete: handleDelete })}
        data={nodes}
        error={!!error}
        loading={loading}
        onOrderChange={setOrder}
        order={order}
        paginationInfo={paginationInfo}
        tableId="product-categories"
        title="Categories"
        totalCountConfig={{
          entityName: 'category',
          isLoading: loading,
          totalCount
        }}
      />
      <EditCreateModal
        configuration={modalConfiguration}
        createForm={({ onClose }) => <CreateProductCategory accountId={accountId} onClose={onClose} />}
        editForm={EditProductCategory}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default ProductCategories;
