import { type FC, useState } from 'react';

import { FormInput, useForm } from '@zen/Components';
import { Switch } from '@zen/DesignSystem';
import { getFieldName } from '@zen/utils/forms';
import useAccount from '@zen/utils/hooks/useAccount';

import type { AccountFormData } from '../../../AccountForms/types';
import type { BaseFormFieldsProps } from '../types';

interface Props extends BaseFormFieldsProps {
  isEditing?: boolean;
  isZencargoReferenceDisabled?: boolean;
}

const AccountDetailsFields: FC<Props> = ({ isZencargoReferenceDisabled = false, fieldPrefix, isEditing = true }) => {
  const { setFieldValue } = useForm<AccountFormData>();
  const { userProfile } = useAccount();
  const [isZrefSwitchChecked, setIsZrefSwitchChecked] = useState(isEditing);

  const zrefFieldName: string = getFieldName('referencePrefix', fieldPrefix);

  const isAdmin = userProfile?.isAdmin;

  if (!isAdmin) {
    return (
      <div className="grid grid-cols-8 gap-8 xl:gap-6">
        <FormInput
          className="col-span-8"
          helperText="This is the internal display name that we will use to label this organisation."
          label="Trading name"
          name={getFieldName('tradingName', fieldPrefix)}
          noMargin={true}
        />
      </div>
    );
  }

  const isZrefFieldDisplayed = !isZrefSwitchChecked && !isZencargoReferenceDisabled;

  return (
    <div className="grid grid-cols-8 gap-8 xl:gap-6">
      <FormInput
        autoFocus={true}
        className={isZrefFieldDisplayed || isZencargoReferenceDisabled ? 'col-span-4' : 'col-span-8'}
        helperText="This is the internal display name that we will use to label this organisation."
        label="Trading name"
        name={getFieldName('tradingName', fieldPrefix)}
        noMargin={true}
      />

      {(!isZrefSwitchChecked || isZencargoReferenceDisabled) && (
        <FormInput
          className="col-span-4"
          disabled={isZencargoReferenceDisabled}
          helperText={
            <>
              Choose a ZREF to help identify your bookings.
              <br />
              Note: This cannot be changed at a later stage.
            </>
          }
          label="Zencargo reference"
          labelDescription="The Zencargo reference (ZREF) is our unique booking identifier, beginning with 'Z' and your company name or abbreviation. Example: ZACME-4589. Also used in invoices."
          name={zrefFieldName}
          noMargin={true}
          onChange={(event) => {
            const userInput: string = event.target.value.toUpperCase();

            setFieldValue(zrefFieldName, `Z${userInput.replace(/^./, '')}`);

            return event.target.value.toUpperCase();
          }}
        />
      )}
      {!isZencargoReferenceDisabled && (
        <div className="col-span-8">
          <Switch
            className="w-full"
            isChecked={isZrefSwitchChecked}
            label="ZREF not required for this account"
            labelHint="Freight payers need a ZREF to ship their goods. If this account is not a freight payer, a ZREF is not required."
            onChange={() => setIsZrefSwitchChecked(!isZrefSwitchChecked)}
          />
        </div>
      )}
    </div>
  );
};

export default AccountDetailsFields;
