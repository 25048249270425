import { type FC, useState } from 'react';

import UpdateAccountDetails from '@zen/Accounts/AccountForms/UpdateAccountDetails';
import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Modal } from '@zen/DesignSystem';

import AccountDetailsItem from '../../components/AccountDetailsItem';

interface Props {
  accountId: string;
  canEdit: boolean;
  referencePrefix?: string | null;
  tradingName: string;
}

const AccountDetails: FC<Props> = (props) => {
  const { canEdit, tradingName, referencePrefix, accountId } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={toggleModal} title="Update account details">
        <UpdateAccountDetails
          initialValues={{
            accountId,
            referencePrefix,
            tradingName
          }}
          onCancel={toggleModal}
          onSuccess={toggleModal}
        />
      </Modal>

      <EditableContentContainer heading="Account details" onEdit={canEdit ? toggleModal : undefined}>
        <AccountDetailsItem fullRow={!referencePrefix} name="Trading name" value={tradingName} />
        {referencePrefix && <AccountDetailsItem name="Zencargo reference" value={referencePrefix} />}
      </EditableContentContainer>
    </>
  );
};

export default AccountDetails;
