import cx from 'classnames';
import type { ReactNode, Ref } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { IconButton } from '../Button';
import ContextMenu from '../ContextMenu';
import type { MenuItemType } from '../ContextMenu/types';
import Tab from './Tab';

export interface TabConfig<T = string> {
  className?: string;
  isActive: boolean;
  label: ReactNode;
  totalCount?: number;
  value: T;
}

interface Props<T = string> {
  className?: string;
  onTabClick: (tab: T) => void;
  tabsConfig: TabConfig<T>[];
}

function Tabs<T = string>({ className, onTabClick, tabsConfig }: Props<T>) {
  const { width } = useWindowSize();
  const [isContextMenuVisible, setIsContextMenuVisible] = useState<boolean>(false);
  const tabsRef: Ref<HTMLDivElement> = useRef(null);
  const activeTab: T | undefined = tabsConfig.find(({ isActive }) => isActive)?.value;

  const classNames: string = cx('flex', { '-ml-6': isContextMenuVisible }, className);
  const tabsClassNames: string = cx('flex items-end overflow-x-auto scrollbar-hidden h-10', { 'pl-6': isContextMenuVisible });

  useEffect(() => {
    const tabsWrapper = tabsRef?.current;

    if (tabsWrapper) {
      const { clientWidth, scrollWidth } = tabsWrapper;
      const showMenu: boolean = scrollWidth > clientWidth;

      setIsContextMenuVisible(showMenu);
    }
  }, [width, tabsConfig.length]);

  useEffect(() => {
    if (!activeTab) return;

    scrollTabIntoView(activeTab);
  }, [activeTab]);

  const scrollTabIntoView = (tabValue: T): void => {
    document
      .querySelector(`[data-tab-value="${tabValue}"]`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  const contextMenuItems: MenuItemType[] = tabsConfig.map(
    (tab): MenuItemType => ({
      ...tab,
      onClick: () => {
        onTabClick(tab.value);
      }
    })
  );

  const renderTab = ({ isActive, label, value, totalCount, className: tabClassName }: TabConfig<T>, index: number): ReactNode => {
    return (
      <Tab
        key={index}
        active={isActive}
        className={tabClassName}
        data-tab-value={value}
        onClick={() => {
          onTabClick(value);
        }}
        totalCount={totalCount}
      >
        {label}
      </Tab>
    );
  };

  return (
    <div className={classNames}>
      <div ref={tabsRef} className={tabsClassNames} data-component="tabs" data-testid="tabs">
        {tabsConfig.map(renderTab)}
      </div>
      {isContextMenuVisible && (
        <div className="bottom-0 right-0 pt-1 pl-4 bg-white border-l border-solid border-grey-lighter">
          <ContextMenu items={contextMenuItems}>
            {() => <IconButton icon="zicon-list-lines" size="medium" variant="ghost" />}
          </ContextMenu>
        </div>
      )}
    </div>
  );
}

export type { Props as TabsProps };

export default Tabs;
