import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductAttributesQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.CoreProductAttributeOrderInput> | GraphQLTypes.CoreProductAttributeOrderInput
  >;
}>;

export type ProductAttributesQueryResult = { __typename?: 'Query' } & {
  coreProductAttributes?: GraphQLTypes.Maybe<
    { __typename?: 'CoreProductAttributeConnection' } & Pick<GraphQLTypes.CoreProductAttributeConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<{ __typename?: 'CoreProductAttribute' } & Pick<GraphQLTypes.CoreProductAttribute, 'id' | 'name' | 'type'>>
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const ProductAttributesDocument = /* #__PURE__ */ gql`
  query productAttributes(
    $accountId: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [CoreProductAttributeOrderInput!]
  ) {
    coreProductAttributes(accountId: $accountId, first: $first, last: $last, after: $after, before: $before, order: $order) {
      nodes {
        id
        name
        type
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useProductAttributesQuery__
 *
 * To run a query within a React component, call `useProductAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductAttributesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useProductAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<ProductAttributesQueryResult, ProductAttributesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductAttributesQueryResult, ProductAttributesQueryVariables>(ProductAttributesDocument, options);
}
export function useProductAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductAttributesQueryResult, ProductAttributesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductAttributesQueryResult, ProductAttributesQueryVariables>(ProductAttributesDocument, options);
}
export type ProductAttributesQueryHookResult = ReturnType<typeof useProductAttributesQuery>;
export type ProductAttributesLazyQueryHookResult = ReturnType<typeof useProductAttributesLazyQuery>;
