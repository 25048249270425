import type { LocationDetailsFormValues, UpdateAccountLocationDetailsInput } from './types';

interface Params {
  accountId: string;
  formValues: LocationDetailsFormValues;
  locationId: string;
}

const prepareValues = (params: Params): UpdateAccountLocationDetailsInput => {
  const { accountId, formValues, locationId } = params;
  const { name, usage } = formValues;

  return {
    accountId: formValues?.locationAccountId || accountId,
    locationId,
    name,
    usageContext: usage
  };
};

export { prepareValues };
