import GoogleMap from 'google-maps-react-markers';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import useSupercluster from 'use-supercluster';

import type { Nullable, Optional } from '@zen/utils/typescript';

import type { MarkerType } from '../../DesignSystem';
import { Marker } from '../../DesignSystem';
import Cluster from './Cluster';
import { getClusterAttributes, getMarkerLocations, hiddenLabelsStyles, preparePoints } from './helpers';
import type { GeoCoordinates, MapApi, MapOptions, SuperclusterPointFeature } from './types';

export const defaultMapLatitude: number = 51.50739999999999;
export const defaultMapLongitude: number = 0.12780000000000769;

const minZoomValue: number = 2;
const defaultCenter: GeoCoordinates = { lat: defaultMapLatitude, lng: defaultMapLongitude };
const defaultBounds: number[] = [-180, 6, 180, 74];

interface Props {
  defaultZoom?: number;
  disableDefaultUI?: boolean;
  hideLabels?: boolean;
  isInteractive?: boolean;
  markers?: MarkerType[];
  maxZoomValue?: number;
  onMapChange?: (lat: number | undefined, lng: number | undefined, index?: number) => void;
  onMarkerClick?: (index: number) => void;
  resizable?: boolean;
  singleMarkerMode?: boolean;
}

const Map: FC<Props> = (props) => {
  const {
    defaultZoom = minZoomValue,
    disableDefaultUI = false,
    hideLabels = false,
    isInteractive = true,
    markers = [],
    maxZoomValue = 16,
    onMapChange,
    onMarkerClick,
    resizable = true,
    singleMarkerMode = false
  } = props;

  const [googleMap, setGoogleMap] = useState<Nullable<MapApi>>(null);
  const [bounds, setBounds] = useState<Nullable<number[]>>(defaultBounds);
  const [zoom, setZoom] = useState<number>(maxZoomValue);

  const maxClusterZoomValue: number = maxZoomValue - 1;
  const mapOptions: MapOptions = {
    fullscreenControl: false,
    minZoom: minZoomValue,
    maxZoom: maxZoomValue,
    streetViewControl: false,
    mapTypeControl: false,
    gestureHandling: 'auto',
    disableDefaultUI: false,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      }
    ]
  };

  useEffect(() => {
    const marker = markers[0];

    if (!marker) return;

    googleMap?.map.panTo({ lat: marker.lat, lng: marker.lng });

    googleMap?.map.setZoom(defaultZoom);
  }, [defaultZoom, markers]); // eslint-disable-line react-hooks/exhaustive-deps

  const points: SuperclusterPointFeature<MarkerType>[] = useMemo(() => preparePoints(markers), [markers]);
  const markerLocations: string = useMemo(() => getMarkerLocations(markers), [markers]);
  const mapDefaultCenter: GeoCoordinates =
    markers?.length === 1 && singleMarkerMode ? { lat: markers?.[0]?.lat, lng: markers?.[0]?.lng } : defaultCenter;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      maxZoom: maxClusterZoomValue,
      radius: 75
    }
  });

  const handleMapChange = ({
    zoom: newZoom,
    bounds: newBounds,
    center
  }: {
    bounds: google.maps.LatLngBounds;
    center: (number | undefined)[]; // lng, lat;
    zoom: number;
  }): void => {
    const ne = newBounds.getNorthEast();
    const sw = newBounds.getSouthWest();

    setZoom(newZoom);
    setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
    if (onMapChange) {
      onMapChange(center[1], center[0]);
    }
  };

  const renderCluster = (cluster: SuperclusterPointFeature<MarkerType>, index: number): ReactNode => {
    const [lng, lat] = cluster.geometry.coordinates;
    const { cluster: isCluster, point_count: pointCount = 0 } = cluster.properties;

    if (isCluster) {
      const clusterLeaves: SuperclusterPointFeature<MarkerType>[] = supercluster.getLeaves(cluster.id);
      const { color, isHighlighted } = getClusterAttributes(clusterLeaves);

      const handleClusterClick = (): void => {
        const expansionZoom: number = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);

        googleMap?.map.setZoom(expansionZoom);
        googleMap?.map.panTo({ lat, lng });
      };

      return (
        <Cluster
          key={cluster.id}
          color={color}
          isHighlighted={isHighlighted}
          lat={lat}
          lng={lng}
          onClick={handleClusterClick}
          pointCount={pointCount}
          totalPoints={points.length}
        />
      );
    }

    return <Marker key={`marker-${index}`} {...cluster.properties} onClick={() => onMarkerClick?.(cluster.properties.index)} />;
  };

  useEffect(() => {
    if (!googleMap) return;

    const selectedMarker: Optional<MarkerType> = markers.find((marker: MarkerType) => marker.isSelected);

    if (selectedMarker) {
      googleMap?.map.panTo({ lat: selectedMarker.lat, lng: selectedMarker.lng });
    }
  }, [markers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!googleMap) return;
    googleMap.map.set('gestureHandling', isInteractive ? 'cooperative' : 'none');
    googleMap.map.set('clickableIcons', !isInteractive);
  }, [isInteractive, googleMap]);

  useEffect(() => {
    const getMarkerGooglePosition = ({ lat, lng }: MarkerType): Optional<google.maps.LatLng> => {
      if (!googleMap) return;

      try {
        return new googleMap.maps.LatLng(lat, lng);
      } catch {
        return null;
      }
    };

    const getMapBounds = (): Optional<google.maps.LatLngBounds> => {
      if (!googleMap) return;

      try {
        const newBounds = new googleMap.maps.LatLngBounds();

        markers.forEach((marker: MarkerType): void => {
          const latLng: Optional<google.maps.LatLng> = getMarkerGooglePosition(marker);

          if (latLng) {
            newBounds.extend(latLng);
          }
        });

        return newBounds;
      } catch {
        return null;
      }
    };

    const fitBounds = (): void => {
      const latLngBounds: Optional<google.maps.LatLngBounds> = getMapBounds();

      const isMarkerCoordinatesSameAsDefault: boolean =
        markers?.length === 1 &&
        singleMarkerMode &&
        markers?.[0].lat === defaultMapLatitude &&
        markers?.[0].lng === defaultMapLongitude;

      if (latLngBounds) {
        googleMap?.map.fitBounds(latLngBounds);
      }

      if (isMarkerCoordinatesSameAsDefault) {
        googleMap?.map.setZoom(minZoomValue);
      }
    };

    if (!googleMap || !markers.length || !resizable) return;

    fitBounds();
  }, [googleMap, markers, markers.length, markerLocations, resizable, singleMarkerMode]);

  const options: MapOptions = {
    ...mapOptions,
    disableDefaultUI,
    ...(hideLabels ? { styles: hiddenLabelsStyles } : {})
  };

  const handleApiLoaded = (mapApi: MapApi): void => {
    if (googleMap) return;

    try {
      setGoogleMap(mapApi);
    } catch {
      /* empty */
    }
  };

  return (
    <div className="relative h-full" data-testid="map">
      <GoogleMap
        defaultCenter={mapDefaultCenter}
        defaultZoom={defaultZoom}
        loadScriptExternally={true}
        onChange={handleMapChange}
        onGoogleApiLoaded={handleApiLoaded}
        options={options}
        status="ready"
      >
        {!singleMarkerMode && clusters.map(renderCluster)}
      </GoogleMap>
      {singleMarkerMode && (
        <div className="absolute left-1/2 bottom-1/2" data-testid="marker-overlay">
          <Marker isDisabled={true} lat={markers?.[0]?.lat} lng={markers?.[0]?.lng} />
        </div>
      )}
    </div>
  );
};

export default Map;
