import { FormRadioGroup } from '@zen/Components';
import type { RadioGroupOption } from '@zen/Components/RadioGroup/types';
import { getFieldName } from '@zen/utils/forms';

type BusinessHoursSetting = 'custom' | 'standard';

const businessHoursOptions: RadioGroupOption<BusinessHoursSetting>[] = [
  {
    label: 'Standard business hours (08:00 - 17:00)',
    value: 'standard'
  },
  {
    label: 'Different business hours',
    value: 'custom'
  }
];

const BusinessHoursRadioGroup = ({ fieldPrefix }: { fieldPrefix?: string }) => {
  return (
    <FormRadioGroup
      className="col-span-8 -mb-5"
      data-testid="business-hours-radio-group"
      label="Business hours of this location"
      name={getFieldName('businessHoursOption', fieldPrefix)}
      noMargin={true}
      options={businessHoursOptions}
      radioAlignment="column"
    />
  );
};

export default BusinessHoursRadioGroup;
