import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpgradeAccountConnectionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpgradeManagedAccountInput;
}>;

export type UpgradeAccountConnectionMutationResult = { __typename?: 'Mutation' } & {
  upgradeManagedAccount: { __typename?: 'UpgradeManagedAccountPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
  };
};

export const UpgradeAccountConnectionDocument = /* #__PURE__ */ gql`
  mutation upgradeAccountConnection($input: UpgradeManagedAccountInput!) {
    upgradeManagedAccount(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpgradeAccountConnectionMutation__
 *
 * To run a mutation, you first call `useUpgradeAccountConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeAccountConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeAccountConnectionMutation, { data, loading, error }] = useUpgradeAccountConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpgradeAccountConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpgradeAccountConnectionMutationResult, UpgradeAccountConnectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpgradeAccountConnectionMutationResult, UpgradeAccountConnectionMutationVariables>(
    UpgradeAccountConnectionDocument,
    options
  );
}
export type UpgradeAccountConnectionMutationHookResult = ReturnType<typeof useUpgradeAccountConnectionMutation>;
