import type { PillType } from '@zen/DesignSystem/types';

import { AccountConnectionTypeEnum, AccountStatus } from '../types';

type PillConfig = { label: string; pillType: PillType };

export const statusMapping: Record<AccountStatus, PillConfig> = {
  [AccountStatus.ACTIVE]: {
    label: 'Active',
    pillType: 'default'
  },
  [AccountStatus.PENDING]: {
    label: 'Pending',
    pillType: 'pending'
  },
  [AccountStatus.INACTIVE]: {
    label: 'Inactive',
    pillType: 'problem'
  },
  [AccountStatus.MANAGED]: {
    label: 'Managed',
    pillType: 'warning'
  }
};

export const connectionTypeMapping: Record<AccountConnectionTypeEnum, string> = {
  [AccountConnectionTypeEnum.AGENT]: 'Agent',
  [AccountConnectionTypeEnum.CUSTOMER]: 'Customer',
  [AccountConnectionTypeEnum.SUPPLIER]: 'Supplier'
};
