import { type FC, useCallback, useMemo, useState } from 'react';

import UpdateAccountNameModal from '@zen/Accounts/AccountDetails/AccountConnections/UpdateAccountNameModal';
import BusinessUnitsSubList from '@zen/Accounts/AccountList/BusinessUnitsSubList';
import Search from '@zen/Components/Search';
import { Table } from '@zen/DesignSystem';
import { type AccountSettingsPermissions, AccountStatus, ConnectionSourceTargetEnum } from '@zen/graphql/types.generated';
import { usePagination } from '@zen/utils/hooks/pagination';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import type { GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables } from '../graphql';
import { useGetAccountConnectionsQuery } from '../graphql';
import type { AccountConnectionsSummary } from '../types';
import AccountConnectionFilters from './AccountConnectionFilters';
import getColumnsConfiguration from './accountConnectionsTableConfiguration';
import AddConnectionButton from './AddConnectionButton';
import UpgradeAccountModal from './UpgradeAccountModal';

const initialFilters = {
  connectionSourceTarget: ConnectionSourceTargetEnum.BOTH,
  status: [AccountStatus.ACTIVE, AccountStatus.MANAGED, AccountStatus.PENDING]
};

interface Props {
  accountId: string;
  permissions?: AccountSettingsPermissions | null;
}

const AccountConnections: FC<Props> = ({ accountId, permissions }) => {
  const [accountToUpgrade, setAccountToUpgrade] = useState<AccountConnectionsSummary>();
  const [accountToUpdate, setAccountToUpdate] = useState<{ connectionAccountId: string; tradingName: string } | null>(null);
  const { filters } = useUrlFilters(initialFilters);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { addSuccess } = useNotification();

  const canCreateConnections = !!permissions?.canCreateConnection?.value;
  const canCreateManagedAccounts = !!permissions?.canCreateManagedAccount?.value;
  const canDeleteConnections = !!permissions?.canDeleteConnection?.value;
  const canInviteConnections = !!permissions?.canInviteConnection?.value;
  const canLinkAgentToCustomer = !!permissions?.canLinkAgentToCustomer?.value;

  const { loading, nodes, paginationInfo, totalCount } = usePagination<
    GetAccountConnectionsQueryResult,
    GetAccountConnectionsQueryVariables,
    AccountConnectionsSummary
  >(
    useGetAccountConnectionsQuery,
    'accountConnections',
    { accountId, accountConnectionsFiltersInput: { searchQuery, ...filters } },
    20,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const onIdCopied = useCallback((): void => {
    addSuccess('Copied to clipboard');
  }, [addSuccess]);

  const onInviteConnection = useCallback(
    (account: AccountConnectionsSummary): void => {
      setAccountToUpgrade(account);
    },
    [setAccountToUpgrade]
  );

  const onUpdateName = useCallback((connectionAccountId: string, tradingName: string): void => {
    setAccountToUpdate({ connectionAccountId, tradingName });
  }, []);

  const handleClose = useCallback(() => {
    setAccountToUpgrade(undefined);
  }, [setAccountToUpgrade]);

  const columns = useMemo(() => {
    return getColumnsConfiguration(
      accountId,
      onIdCopied,
      onInviteConnection,
      onUpdateName,
      canDeleteConnections,
      canInviteConnections
    );
  }, [accountId, onIdCopied, onInviteConnection, onUpdateName, canDeleteConnections, canInviteConnections]);

  const data = nodes.map((item) => ({ ...item, nestedRowItems: item.account.businessUnits }));

  return (
    <>
      <Table<AccountConnectionsSummary>
        actions={
          (canCreateConnections || canCreateManagedAccounts || canInviteConnections || canLinkAgentToCustomer) && (
            <AddConnectionButton accountId={accountId} permissions={permissions} />
          )
        }
        additionalActions={
          <div className="flex space-x-2">
            <Search
              collapsible={true}
              onClear={() => setSearchQuery('')}
              onSubmit={setSearchQuery}
              placeholder="Search connections..."
              size="compact"
              widthClassName="w-48"
            />
            <AccountConnectionFilters />
          </div>
        }
        columns={columns}
        data={data}
        emptyText="No connections"
        loading={loading}
        paginationInfo={paginationInfo}
        renderExpandedRow={(record, index) => {
          return (
            <BusinessUnitsSubList
              businessUnits={record.account?.businessUnits || []}
              canEditBusinessUnit={false}
              tableId={`accountDivisionList-${index}`}
            />
          );
        }}
        rowKey="account.id"
        tableId="accountConnections"
        title="Connections"
        totalCountConfig={{
          totalCount,
          entityName: 'connection'
        }}
      />

      {accountToUpdate && (
        <UpdateAccountNameModal
          accountId={accountToUpdate.connectionAccountId}
          handleClose={() => setAccountToUpdate(null)}
          sourceAccountId={accountId}
          tradingName={accountToUpdate.tradingName}
        />
      )}
      <UpgradeAccountModal accountId={accountId} accountToUpgrade={accountToUpgrade} handleClose={handleClose} />
    </>
  );
};

export default AccountConnections;
