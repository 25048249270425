import type { FC } from 'react';
import { useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import { AccountConnectionTypeEnum, AccountStatus } from '../../types';
import DeleteConnectionModal from '../DeleteConnectionModal';

interface Props {
  canDeleteConnection: boolean;
  connectionType: AccountConnectionTypeEnum;
  sourceAccountId: string;
  status: AccountStatus;
  targetAccountId: string;
  targetAccountName: string;
}

const DeleteConnectionButton: FC<Props> = (props) => {
  const { canDeleteConnection, connectionType, sourceAccountId, status, targetAccountId, targetAccountName } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCustomerConnection: boolean = connectionType === AccountConnectionTypeEnum.CUSTOMER;

  const getButtonTitle = (): string => {
    if (status === AccountStatus.PENDING) {
      return 'Revoke invitation';
    }

    return isCustomerConnection ? 'Customers cannot be deleted' : 'Delete';
  };

  const toggleModal = (): void => setIsModalOpen((isOpen: boolean) => !isOpen);

  return (
    <>
      <DeleteConnectionModal
        connectionType={connectionType}
        isOpen={isModalOpen}
        onClose={toggleModal}
        sourceAccountId={sourceAccountId}
        status={status}
        targetAccountId={targetAccountId}
        targetAccountName={targetAccountName}
      />
      <IconButton
        disabled={!canDeleteConnection || isCustomerConnection}
        icon="zicon-trash"
        onClick={toggleModal}
        size="medium"
        title={getButtonTitle()}
        variant="ghost"
      />
    </>
  );
};

export default DeleteConnectionButton;
export type { Props as DeleteConnectionButtonProps };
