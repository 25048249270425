import { get } from 'lodash';
import type { FC } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Switch } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateAccountDemoModeMutation } from '../../graphql';

interface Props {
  accountId: string;
  canEdit: boolean;
  demoMode: boolean;
}

const DemoAccountDetails: FC<Props> = ({ accountId, canEdit, demoMode }) => {
  const { addError, addSuccess } = useNotification();
  const [updateDemoMode, { loading }] = useUpdateAccountDemoModeMutation();

  const handleSubmit = async (isDemoMode: boolean) => {
    await performMutation({
      mutationFn: () =>
        updateDemoMode({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountDetails'],
          variables: {
            input: {
              accountId,
              demoMode: isDemoMode
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Demo/test mode could not be updated.'));
      },
      onSuccess: () => {
        addSuccess(`Demo/test mode ${isDemoMode ? 'enabled' : 'disabled'}.`);
      }
    });
  };

  return (
    <EditableContentContainer heading="Demo/testing account">
      <Switch
        className="col-span-12"
        isChecked={demoMode}
        isDisabled={!canEdit || loading}
        label="This account is for demo/testing purposes"
        labelHint="This will disable shipments from being booked and shipments won't be included in business analysis."
        onChange={handleSubmit}
      />
    </EditableContentContainer>
  );
};

export default DemoAccountDetails;
