import { CoreProductAttributeOrderInputField, CoreProductAttributeType } from '@zen/graphql/types.generated';

export type { CoreProductAttribute, CoreProductAttributeOrderInput } from '@zen/graphql/types.generated';

export { CoreProductAttributeOrderInputField, CoreProductAttributeType };

export const productAttributeTypeLabels: Record<CoreProductAttributeType, string> = {
  [CoreProductAttributeType.DATE]: 'Date',
  [CoreProductAttributeType.NUMBER]: 'Number',
  [CoreProductAttributeType.TEXT]: 'Text'
};
