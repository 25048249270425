import type { FC, ReactNode, SyntheticEvent } from 'react';

import type { IconName } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Button from '../Button';
import type { ButtonVariant } from '../Button/types';
import { BaseModal } from '../Modal';
import { Headline } from '../Typography';

export interface Props {
  buttonVariant?: ButtonVariant;
  cancelLabel?: string;
  confirmIcon?: IconName;
  confirmLabel?: string;
  disabled?: boolean;
  hasCancelButton?: boolean;
  header?: ReactNode;
  isLoading?: boolean;
  isOpen: boolean;
  message?: ReactNode;
  onClose: () => void;
  onConfirm: () => void;
}

export const displayName = 'Design System/Dialog';

const Dialog: FC<Props> = (props) => {
  const {
    cancelLabel = 'Cancel',
    buttonVariant,
    confirmIcon,
    confirmLabel = 'Confirm',
    disabled = false,
    hasCancelButton = true,
    header = 'Are you sure?',
    isOpen,
    isLoading = false,
    message,
    onClose,
    onConfirm
  } = props;

  const { trackEvent } = useTracking();

  const handleClose = (event: SyntheticEvent | MouseEvent | KeyboardEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'close', event);

    trackEvent(trackingEvent);
    onClose();
  };

  const handleConfirm = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'confirm', event);

    trackEvent(trackingEvent);
    onConfirm();
  };

  return (
    <BaseModal
      className="items-center mb-16"
      data-component="dialog"
      isOpen={isOpen}
      {...createTrackingParentAttribute('dialog')}
    >
      <div data-testid="dialog">
        <div className="p-6 w-120">
          <div data-testid="dialog-headline" {...createTrackingLabelAttribute()}>
            <Headline level={3}>{header}</Headline>
          </div>
          {message && (
            <div className="mt-1 text-sm leading-normal whitespace-pre-line text-grey-dark" data-testid="dialog-message">
              {message}
            </div>
          )}
        </div>
        <div className="flex justify-end p-4 border-t border-solid border-grey-lighter">
          {hasCancelButton && (
            <Button className="mr-4" data-testid="dialog-cancel-button" onClick={handleClose} variant="ghost">
              {cancelLabel}
            </Button>
          )}
          <Button
            data-testid="dialog-confirm-button"
            disabled={disabled}
            iconLeft={confirmIcon}
            isLoading={isLoading}
            onClick={handleConfirm}
            variant={buttonVariant}
          >
            {confirmLabel}
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default Dialog;
