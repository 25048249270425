import { get } from 'lodash';
import { type FC, type ReactNode } from 'react';

import { Form, FormButtons } from '@zen/Components';
import FormAccountMultiSelect from '@zen/Components/Form/FormAccountMultiSelect';
import type { FormInstance } from '@zen/Components/types';
import { Banner, Button } from '@zen/DesignSystem';
import { OrganizationalRoleEnum } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { AccountConnectionTypeEnum } from '../../types';
import { useLinkAccountsMutation } from './graphql';
import validationSchema from './linkAccountsForm.validation';
import type { LinkAccountsFormValues } from './types';

interface Props {
  accountId: string;
  closeModals: () => void;
  connectionType: AccountConnectionTypeEnum;
  organizationalRole: OrganizationalRoleEnum;
}

const LinkAccountsForm: FC<Props> = ({ closeModals, connectionType, accountId, organizationalRole }) => {
  const [linkAccounts] = useLinkAccountsMutation({ refetchQueries: ['getAccountConnections'] });
  const { addError, addSuccess } = useNotification();

  const handleSubmit = async (values: LinkAccountsFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        linkAccounts({
          variables: {
            input: {
              targetAccountIds: values.accounts,
              sourceAccountId: accountId,
              connectionType
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Failed to link the selected accounts. Please try again.'));
      },
      onSuccess: () => {
        addSuccess('Accounts were successfully linked.');
        closeModals();
      }
    });
  };
  const renderFormButtons = ({ isSubmitting }: FormInstance<LinkAccountsFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add connection">
      <Button data-testid="cancel-button" onClick={closeModals} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const getBannerMessage = (): string => {
    switch (connectionType) {
      case AccountConnectionTypeEnum.AGENT:
        return "Connections you add will be listed as 'Agent' in your connections.";

      case AccountConnectionTypeEnum.SUPPLIER:
        return "Connections you add will be listed as 'Supplier' in your connections.";

      case AccountConnectionTypeEnum.CUSTOMER:
        if (organizationalRole === OrganizationalRoleEnum.AGENT)
          return "Connections you add will be listed as 'Supplier' in your connections.";
        if (organizationalRole === OrganizationalRoleEnum.CONSIGNMENT_PARTY)
          return "Connections you add will be listed as 'Customer' in your connections.";
    }

    return '';
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={{ accounts: [] }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <FormAccountMultiSelect excludeAccounts={[accountId]} name="accounts" organizationalRole={[organizationalRole]} />
          <Banner message={getBannerMessage()} />
        </>
      )}
    </Form>
  );
};

export default LinkAccountsForm;
