import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { FormInput } from '@zen/Components';
import { Headline } from '@zen/DesignSystem';
import { getFieldName } from '@zen/utils/forms';
import type { Nullable } from '@zen/utils/typescript';

import type { AddressInput } from '../../../AccountForms/types';
import AddressFields from '../AddressFields';
import FormAddressGeolocationInput from '../FormAddressGeolocationInput';
import LocationUsageRadioGroup from '../LocationUsageRadioGroup';
import type { BaseFormFieldsProps } from '../types';

interface Props extends BaseFormFieldsProps {
  address: AddressInput;
  autoFocusNameField?: boolean;
  geolocation?: Nullable<{ latitude: number; longitude: number }>;
  noMargin?: boolean;
  showLocationDetails?: boolean;
  showLocationUsage?: boolean;
}

const ShippingLocationFields: FC<Props> = ({
  address,
  autoFocusNameField = true,
  fieldPrefix,
  geolocation,
  noMargin,
  showLocationDetails = true,
  showLocationUsage = true
}) => {
  const horizontalLine: ReactNode = <hr className="col-span-8 border-grey-lighter" />;
  const className: string = cx('grid grid-cols-8 gap-x-8 xl:gap-x-6', { 'mb-4': !noMargin });

  const getInitialCoordinates = (): { lat: number; lng: number } | undefined => {
    if (geolocation) {
      const { latitude, longitude } = geolocation;

      return { lat: latitude, lng: longitude };
    }
  };

  return (
    <div className={className} data-testid="shipping-location-fields">
      {showLocationDetails && (
        <>
          <FormInput
            autoFocus={autoFocusNameField}
            className="col-span-8"
            label="Location name"
            name={getFieldName('name', fieldPrefix)}
          />
          {showLocationUsage && (
            <div className="col-span-8">
              <LocationUsageRadioGroup name={getFieldName('usageContext', fieldPrefix)} />
            </div>
          )}
          {horizontalLine}
          <Headline className="col-span-4 my-4" level={4}>
            Address
          </Headline>
        </>
      )}
      <AddressFields fieldPrefix="shippingLocationAddress" />
      <div className="col-span-8 pt-4">
        <FormAddressGeolocationInput
          address={address}
          initialCoordinates={getInitialCoordinates()}
          name={getFieldName('geolocation', fieldPrefix)}
        />
      </div>
    </div>
  );
};

export default ShippingLocationFields;
export type { Props as ShippingLocationFieldsProps };
