const PRODUCT_SETTINGS_RELATIVE_INDEX_PATH = 'product-settings';

export const PRODUCT_SETTINGS_PREFIX = `/dashboard/${PRODUCT_SETTINGS_RELATIVE_INDEX_PATH}`;

interface ProductSettingsRoutes {
  documentTemplatesCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  documentTemplatesEdit: {
    getUrl: (documentTemplateId: string) => string;
    path: string;
    relativePath: string;
  };
  documentTemplatesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  globalDirectory: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  globalLocationDetails: {
    getUrl: (locationId: string) => string;
    path: string;
    relativePath: string;
  };
  globalMembers: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  manageTerminals: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  productSettingsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  unloCodeTerminals: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const productSettingsRoutes: ProductSettingsRoutes = {
  documentTemplatesCreate: {
    path: `${PRODUCT_SETTINGS_PREFIX}/document-templates/create`,
    relativePath: 'create',
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/document-templates/create`
  },
  documentTemplatesEdit: {
    path: `${PRODUCT_SETTINGS_PREFIX}/document-templates/edit/:documentTemplateId`,
    relativePath: 'edit/:documentTemplateId',
    getUrl: (documentTemplateId: string) => `${PRODUCT_SETTINGS_PREFIX}/document-templates/edit/${documentTemplateId}`
  },
  documentTemplatesIndex: {
    path: `${PRODUCT_SETTINGS_PREFIX}/document-templates`,
    relativePath: 'document-templates/*',
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/document-templates`
  },
  globalDirectory: {
    path: `${PRODUCT_SETTINGS_PREFIX}/global-directory`,
    relativePath: 'global-directory/*',
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/global-directory`
  },
  globalLocationDetails: {
    path: `${PRODUCT_SETTINGS_PREFIX}/global-directory/:locationId`,
    relativePath: 'global-directory/:locationId',
    getUrl: (locationId: string) => `${PRODUCT_SETTINGS_PREFIX}/global-directory/${locationId}`
  },
  globalMembers: {
    path: `${PRODUCT_SETTINGS_PREFIX}/global-members`,
    relativePath: 'global-members/*',
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/global-members`
  },
  manageTerminals: {
    path: `${PRODUCT_SETTINGS_PREFIX}/terminals`,
    relativePath: 'terminals/*',
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/terminals`
  },
  productSettingsIndex: {
    path: `${PRODUCT_SETTINGS_PREFIX}/*`,
    relativePath: `${PRODUCT_SETTINGS_RELATIVE_INDEX_PATH}/*`,
    getUrl: () => PRODUCT_SETTINGS_PREFIX
  },
  unloCodeTerminals: {
    path: `${PRODUCT_SETTINGS_PREFIX}/terminals/unlocodes`,
    relativePath: 'unlocodes',
    getUrl: () => `${PRODUCT_SETTINGS_PREFIX}/terminals/unlocodes`
  }
};

export default productSettingsRoutes;
