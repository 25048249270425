import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { useDefaultUserGroup } from '@zen/Accounts/hooks/useDefaultUserGroup';
import { Form, FormButtons, FormMultiText, FormRadioGroup } from '@zen/Components';
import type { FormInstance } from '@zen/Components/types';
import { Button, Modal } from '@zen/DesignSystem';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import FormBusinessUnitsSelect from '../../components/FormAccountBusinessUnitsSelect';
import FormAccountUserGroupsSelect from '../../components/FormAccountUserGroupsSelect';
import { type InviteUsersMutationResult, useGetAccountUserGroupsQuery, useInviteUsersMutation } from '../../graphql';
import validationSchema from './addMemberModal.validation';
import { businessUnitsAssignmentOptions } from './helpers';
import type { MemberFormValues } from './types';

interface Props {
  accountHasBusinessUnits: boolean;
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddMemberModal: FC<Props> = ({ accountHasBusinessUnits, accountId, isOpen, onClose, onSuccess }) => {
  const [inviteUsers] = useInviteUsersMutation();
  const { addError, addSuccess } = useNotification();
  const { userProfile } = useAccount();
  const { userGroup } = useDefaultUserGroup(accountId);
  const { data } = useGetAccountUserGroupsQuery({ variables: { accountId } });

  const adminUserGroup: string = (data?.accountUserGroups || []).find((group) => group.name === 'Admin')?.userGroupId || '';

  const renderFormButtons = ({ isSubmitting }: FormInstance<MemberFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Add member">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSubmit = async (values: MemberFormValues): Promise<IOkOrErrorResult> => {
    const { businessUnits, businessUnitsAssigments, emails, role } = values;
    const { firstName, lastName, registeredCompanyName } = userProfile;

    return performFormMutation({
      mutationFn: () =>
        inviteUsers({
          variables: {
            input: {
              accountId,
              emails,
              businessUnits: businessUnitsAssigments === 'all-business-units' ? [] : businessUnits,
              role,
              invitedBy: {
                accountName: registeredCompanyName,
                userFullName: `${firstName} ${lastName}`
              }
            }
          }
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (_: InviteUsersMutationResult, { emails }: MemberFormValues): void => {
    addSuccess(`${pluralize('Member', emails.length)} successfully added.`);
    onSuccess();
  };

  const initialValues: MemberFormValues = {
    emails: [],
    accountId,
    businessUnits: [],
    businessUnitsAssigments: 'all-business-units',
    role: userGroup
  };

  return (
    <Modal closeOnClickAway={false} isOpen={isOpen} onClose={onClose} title="Add member">
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values }: FormInstance<MemberFormValues>) => {
          const isSpecificBusinessUnitsOptionSelected: boolean = values.businessUnitsAssigments === 'specific-business-units';
          const isAdminUserGroupSelected: boolean = values.role === adminUserGroup;

          const handleUserGroupChange = (memberUserGroupId: Nullable<string>): void => {
            if (memberUserGroupId === adminUserGroup) {
              setFieldValue('businessUnitsAssigments', 'all-business-units');
            }
          };

          return (
            <>
              <FormMultiText
                helperText="Provide multiple emails to invite users in bulk. We cannot send invitations to distribution lists."
                label="Emails"
                name="emails"
                placeholder="name@company.com, name@company.co.uk..."
                separateOn={['Tab', 'Comma', 'Space', 'Enter']}
              />
              <FormAccountUserGroupsSelect accountId={accountId} label="Invite as" name="role" onChange={handleUserGroupChange} />
              {accountHasBusinessUnits && (
                <FormRadioGroup
                  disabled={isAdminUserGroupSelected}
                  label="Grant access to:"
                  name="businessUnitsAssigments"
                  noMargin={true}
                  options={businessUnitsAssignmentOptions}
                  radioAlignment="column"
                />
              )}
              {isSpecificBusinessUnitsOptionSelected && (
                <FormBusinessUnitsSelect accountId={accountId} className="mt-4" isOptional={true} name="businessUnits" />
              )}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddMemberModal;
export type { Props as AddMemberModalProps };
